import { useContext, useMemo } from "react";

import { AppContext } from "../context/AppContext";

export const useTheme = () => {
  const { theme } = useContext(AppContext) || {};

  const { mode } = theme || {};

  const monacoTheme = useMemo(() => mode === "light" ? "vs-light" : "vs-dark", [mode]);
  
  return {
    ...theme,
    monacoTheme
  };
};
