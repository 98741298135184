import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

export const Documentation = () => {
  return (
    <List disablePadding>
      <ListItem disablePadding>
        <ListItemButton
          component="a"
          href="https://kodjin.com/terms-of-use/"
          target="_blank"
        >
          <ListItemText primary="Terms of use" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component="a"
          href="https://kodjin.com/fhir-profiler-instructions/"
          target="_blank"
        >
          <ListItemText primary="Documentation" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component="a"
          href="https://kodjin.com/feedback/"
          target="_blank"
        >
          <ListItemText primary="Leave your feedback" />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
