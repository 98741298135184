import { useState, useCallback } from "react";

import { AlertData } from "../types";

export const useAlert = () => {
  const [data, setData] = useState<AlertData>();  

  const toggle = useCallback((data?: AlertData) => {    
    setData(data);
  }, [setData]);

  return {
    data,
    toggle
  };
};
