export const HEADER_HEIGHT = "46px";

export const CONTENT_HEIGHT = "calc(100vh - 46px)";

export const COLOR_SCHEMA = {
  dark: {
    base: "#1e1e1e",
    contrast: "#252526",
    text: "#fff",
    link: "#90caf9",
    highlight: "#f88070"
  },
  light: {
    base: "#ffffff",
    contrast: "#f3f3f3",
    text: "#000000",
    link: "#006ab1",
    highlight: "#f88070"
  }
};
