import { useContext, useCallback } from "react";

import { AppContext } from "../context/AppContext";
import { DialogData } from "../types";

export const useDialog = () => {
  const { dialog: { data, setData } } = useContext(AppContext) || {};
  
  const getSubmitted = useCallback(({
    title,
    description,
    fields,
    okText,
    cancelText
  }: Omit<NonNullable<DialogData>, "resolve">) => 
    new Promise<Record<string, string> | boolean>((resolve) => {      
      setData({ 
        title,
        description,
        fields,
        okText,
        cancelText,
        resolve
      });
    }), [setData]);

  return { data: data, setData, getSubmitted };
};
