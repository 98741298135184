import { ChangeEvent } from "react";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { Text } from "@profiler/ui";

import {
  TerminologyBodyType,
  TerminologyBodyTypeValues,
} from "../../types/configUrls";
import { DefaultStateType } from "./index";

type TerminologyConfigType = {
  state: DefaultStateType;
  onChange: (
    value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const TerminologyConfig = ({
  state,
  onChange,
}: TerminologyConfigType) => (
  <Box mb={3}>
    <Text fontSize={4} mb={2} color="text">
      Terminology validation body
    </Text>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      <RadioGroup
        onChange={onChange}
        row
        aria-labelledby="terminology"
        value={state[TerminologyBodyType.TerminologyValidationBodyType]}
        name={TerminologyBodyType.TerminologyValidationBodyType}
        id={TerminologyBodyType.TerminologyValidationBodyType}
      >
        <FormControlLabel
          value={TerminologyBodyTypeValues.FhirResource}
          control={<Radio size="small" />}
          label={
            <Text fontSize={"14px"} color="text">
              Send as FHIR-Resource
            </Text>
          }
        />
        <FormControlLabel
          value={TerminologyBodyTypeValues.FormData}
          control={<Radio size="small" />}
          label={
            <Text fontSize={"14px"} color="text">
              Send as a file
            </Text>
          }
        />
      </RadioGroup>
    </Box>
  </Box>
);
