import { useMemo } from "react";

import { useFileSystem } from "../../../hooks";
import { ROOT_FOLDER } from "../../../constants";
import { isFile } from "../../../types";

type UseTargetItemParams = {
  targetItemId: string | null;
};

export const useTargetItem = ({ 
  targetItemId
}: UseTargetItemParams) => {
  const { fileSystem } = useFileSystem();

  const targetItem = useMemo(() => {
    return targetItemId ? fileSystem[targetItemId] : null;
  }, [fileSystem, targetItemId]);

  const isRoot = targetItemId === ROOT_FOLDER;

  return {
    targetItem,
    isRoot,
    isFolder: isRoot || (!!targetItem && !isFile(targetItem))
  }
};