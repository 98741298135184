import { useFilePicker } from "use-file-picker";
import {
  FileContent,
  SelectedFilesOrErrors,
} from "use-file-picker/src/interfaces";

import { AlertType } from "@profiler/ui";
import { useAlert, useFileSystem } from "../../../hooks";
import { FileSystemActionType } from "../../../types";
import { ROOT_FOLDER } from "../../../constants";
import { validateName as getNameValidator } from "../validate";

export type UseOpenFilesResult = {
  openFileSelector: () => void;
};

export const useOpenFiles = (): UseOpenFilesResult => {
  const { dispatch, fileSystem } = useFileSystem();
  const { toggle: toggleAlert } = useAlert();

  const onFilesSelected = ({ filesContent }: SelectedFilesOrErrors) => {
    const validateName = getNameValidator({
      targetItemId: ROOT_FOLDER,
      fileSystem,
    });

    filesContent?.forEach((file: FileContent) => {
      const nameError = validateName(file.name);

      if (!nameError) {
        dispatch({
          type: FileSystemActionType.AddFile,
          payload: {
            parentId: ROOT_FOLDER,
            name: file.name,
            content: file.content,
          },
        });
      } else {
        toggleAlert({
          message: nameError,
          type: AlertType.Error,
        });
      }
    });
  };

  const [openFileSelector] = useFilePicker({
    accept: ".json",
    multiple: true,
    onFilesSelected,
  });

  return {
    openFileSelector,
  };
};
