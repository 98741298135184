import { ChangeEvent } from "react";
import { Box, TextField } from "@mui/material";

import { Text } from "@profiler/ui";

import { FORM_CONFIG_URL } from "./constants";
import { DefaultStateType } from "./index";

type ApiUrlsType = {
  state: DefaultStateType;
  onChange: (
    value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const ApiUrls = ({ state, onChange }: ApiUrlsType) => (
  <Box mb={3}>
    <Text fontSize={4} mb={4} color="text">
      URL
    </Text>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      {FORM_CONFIG_URL.map((item) => (
        <TextField
          sx={{ width: "49%", input: { fontSize: 14 } }}
          key={item.id}
          name={item.id}
          label={item.label}
          id={item.id}
          size="small"
          value={state[item.id]}
          onChange={onChange}
        />
      ))}
    </Box>
  </Box>
);
