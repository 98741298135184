import React, { useCallback } from "react";
import { DriveFileRenameOutline } from "@mui/icons-material";

import { useFileSystem, useDialog } from "../../../hooks";
import { useTargetItem } from "./useTargetItem";
import { FileSystemActionType } from "../../../types";
import { UseMenuItemParams, UseMenuItemResult } from "../types";
import { validateNameChange } from "../validate";

export const useRenameItem = ({
  targetItemId,
  closeMenu
}: UseMenuItemParams): UseMenuItemResult => {
  const { dispatch, fileSystem } = useFileSystem();

  const { isRoot, targetItem } = useTargetItem({ targetItemId });

  const { getSubmitted } = useDialog();

  const onRename = useCallback(async () => {
    closeMenu();

    if (!targetItemId || !targetItem) return;

    const result = await getSubmitted({
      title: "Rename",
      fields: [{
        name: "name",
        required: true,
        validate: validateNameChange({ targetItem, fileSystem }),
        defaultValue: targetItem.name
      }]
    });

    if (typeof result === "object" && result.name) {
      dispatch({
        type: FileSystemActionType.Rename,
        payload: {
          id: targetItemId,
          name: result.name
        }
      });
    }
  }, [
    targetItemId,
    targetItem,
    fileSystem,
    closeMenu,
    getSubmitted,
    dispatch
  ]);

  return {
    available: !isRoot,
    name: "Rename",
    icon: <DriveFileRenameOutline />,
    onClick: onRename
  };
};
