import { useState } from "react";

import { DialogData } from "../types";

export const useDialog = () => {
  const [data, setData] = useState<DialogData>(null);

  return {
    data,
    setData
  };
};