import { useRef } from "react";
import type Monaco from "monaco-editor";

export const useEditor = () => {
  const editorRef = useRef<Monaco.editor.IStandaloneCodeEditor>(
    {} as Monaco.editor.IStandaloneCodeEditor
  );

  return editorRef;
};
