import {
  FileSystemState,
  FileSystemAction,
  FileSystemActionType,
  FileSystemActionPayload,
  FileSystemDiff,
  Folder,
  File,
  isFile,
  FileEntry,
  ActionMap,
  forceTypeCast,
} from "../../types";
import { getFileSystemItemId } from "../../utils";
import { EMPTY_PROFILE } from "../../constants";
import { selectActiveId } from "./selectors";

type UpdatePathsActionType =
  | FileSystemActionType.Rename
  | FileSystemActionType.Move;

type UpdatePathsAction = ActionMap<FileSystemActionPayload>[UpdatePathsActionType];

const updatePathsAction = (
  state: FileSystemState,
  action: UpdatePathsAction,
  newItem: Folder | File
): FileSystemState => {
  const newId = getFileSystemItemId(newItem);

  const files: (FileEntry & { prevId: string })[] = [];

  const setActiveDiff: FileSystemDiff[] = [];

  const activeFileId = selectActiveId(state.fileSystem);

  const addDiff = (id: string, newId: string, newItem: Folder | File) => {
    if (isFile(newItem)) {
      files.push({
        ...newItem,
        prevId: id,
        id: getFileSystemItemId(newItem),
      });

      if (id === activeFileId) {
        setActiveDiff.push({
          type: FileSystemActionType.SetActive,
          payload: {
            id: newId,
          },
        });
      }
    }
  };

  // Change all related items paths.
  const entries = Object.entries(state.fileSystem).map(([id, item]) => {
    if (id === action.payload.id) {
      addDiff(id, newId, newItem);

      return [newId, newItem];
    }

    if (item.path.startsWith(action.payload.id)) {
      const newItem = {
        ...item,
        path: item.path.replace(action.payload.id, newId),
      };

      const newItemId = getFileSystemItemId(newItem);

      addDiff(id, newItemId, newItem);

      return [newItemId, newItem];
    }
    return [id, item];
  });

  return {
    fileSystem: Object.fromEntries(entries),
    diff: files.length
      ? forceTypeCast<FileSystemDiff[]>([
          {
            type: action.type,
            payload: {
              files,
            },
          },
          ...setActiveDiff,
        ])
      : state.diff,
  };
};

export const fileSystemReducer = (
  state: FileSystemState,
  action: FileSystemAction
) => {
  switch (action.type) {
    case FileSystemActionType.AddFolder: {
      const item: Folder = {
        path: action.payload.parentId,
        name: action.payload.name,
      };

      return {
        fileSystem: {
          ...state.fileSystem,
          [getFileSystemItemId(item)]: item,
        },
        diff: state.diff,
      };
    }
    case FileSystemActionType.AddFile: {
      const fileSystem = { ...state.fileSystem };

      const activeId = selectActiveId(fileSystem);

      const activeFile = fileSystem[activeId];

      if (isFile(activeFile)) {
        activeFile.isActive = false;
      }

      const item: File = {
        path: action.payload.parentId,
        name: action.payload.name,
        content: action.payload.content || EMPTY_PROFILE,
        isActive: true,
      };

      const id = getFileSystemItemId(item);

      fileSystem[getFileSystemItemId(item)] = item;

      return {
        fileSystem,
        diff: [
          {
            type: action.type,
            payload: {
              file: {
                ...item,
                id,
              },
            },
          },
          {
            type: FileSystemActionType.SetActive,
            payload: {
              id,
            },
          },
        ] as FileSystemDiff[],
      };
    }

    case FileSystemActionType.Rename: {
      const currentItem = state.fileSystem[action.payload.id];

      const item = {
        ...currentItem,
        name: action.payload.name,
      };

      return updatePathsAction(state, action, item);
    }

    case FileSystemActionType.Move: {
      const currentItem = state.fileSystem[action.payload.id];

      const item = {
        ...currentItem,
        path: action.payload.parentId,
      };

      return updatePathsAction(state, action, item);
    }

    case FileSystemActionType.Remove: {
      const files: FileEntry[] = [];

      // Filter item to remove and all its children.
      const entries = Object.entries(state.fileSystem)
        .map(([id, item]) => {
          if (
            id === action.payload.id ||
            item.path.startsWith(action.payload.id)
          ) {
            if (isFile(item)) files.push({ ...item, id });
            return null;
          }
          return [id, item];
        })
        .filter((entry): entry is [string, Folder | File] => entry !== null);

      return {
        fileSystem: Object.fromEntries(entries),
        diff: [
          {
            type: action.type,
            payload: {
              files,
            },
          },
        ] as FileSystemDiff[],
      };
    }

    case FileSystemActionType.RemoveAll: {
      const files: FileEntry[] = [];

      return {
        fileSystem: {},
        diff: [
          {
            type: action.type,
            payload: {
              files,
            },
          },
        ] as FileSystemDiff[],
      };
    }

    case FileSystemActionType.Change: {
      const item = state.fileSystem[action.payload.id];

      const { fileSystem, diff } = state;

      fileSystem[action.payload.id] = {
        ...item,
        content: action.payload.content,
      };

      return {
        fileSystem,
        diff,
      };
    }

    case FileSystemActionType.SetActive: {
      const entries = Object.entries(state.fileSystem).map(([id, item]) => {
        if (id === action.payload.id) {
          return [id, { ...item, isActive: true }];
        }
        return [id, { ...item, isActive: false }];
      });

      return {
        fileSystem: Object.fromEntries(entries),
        diff: [
          {
            type: action.type,
            payload: action.payload,
          },
        ] as FileSystemDiff[],
      };
    }
    default:
      throw new Error(`Unknown action ${JSON.stringify(action, null, 2)}`);
  }
};
