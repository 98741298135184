import {
  AuthConfigTypeValues,
  AuthValues,
  ConfigSnippetValues,
  ConfigUrlsTypes,
  TerminologyBodyType,
} from "../../types/configUrls";

export const FORM_CONFIG_URL: { label: string; id: ConfigUrlsTypes }[] = [
  {
    label: "FHIR server base url",
    id: ConfigUrlsTypes.FhirBaseApiUrl,
  },
  {
    label: "Terminology Url",
    id: ConfigUrlsTypes.TerminologyUrl,
  },
];

export const FORM_CONFIG_SNIPPET: {
  label: string;
  id: ConfigSnippetValues;
}[] = [
  {
    label: "Publisher",
    id: ConfigSnippetValues.publisher,
  },
  {
    label: "Version",
    id: ConfigSnippetValues.version,
  },
  {
    label: "Date",
    id: ConfigSnippetValues.date,
  },
  {
    label: "FhirVersion",
    id: ConfigSnippetValues.fhirVersion,
  },
  {
    label: "Status",
    id: ConfigSnippetValues.status,
  },
];

export const defaultSettings = () => ({
  [ConfigUrlsTypes.TerminologyUrl]:
    localStorage.getItem(ConfigUrlsTypes.TerminologyUrl) || "",
  [ConfigUrlsTypes.FhirBaseApiUrl]:
    localStorage.getItem(ConfigUrlsTypes.FhirBaseApiUrl) || "",
  [TerminologyBodyType.TerminologyValidationBodyType]:
    localStorage.getItem(TerminologyBodyType.TerminologyValidationBodyType) ||
    "",

  [ConfigSnippetValues.publisher]:
    localStorage.getItem(ConfigSnippetValues.publisher) || "",
  [ConfigSnippetValues.version]:
    localStorage.getItem(ConfigSnippetValues.version) || "",
  [ConfigSnippetValues.date]:
    localStorage.getItem(ConfigSnippetValues.date) || "",
  [ConfigSnippetValues.fhirVersion]:
    localStorage.getItem(ConfigSnippetValues.fhirVersion) || "",
  [ConfigSnippetValues.status]:
    localStorage.getItem(ConfigSnippetValues.status) || "",

  [AuthValues.authType]: localStorage.getItem(AuthValues.authType) || "",
  [AuthValues.accessTokenUrl]:
    localStorage.getItem(AuthValues.accessTokenUrl) || "",
  [AuthValues.bearerToken]: localStorage.getItem(AuthValues.bearerToken) || "",
  [AuthValues.clientSecret]:
    localStorage.getItem(AuthValues.clientSecret) || "",
  [AuthValues.clientId]: localStorage.getItem(AuthValues.clientId) || "",
  [AuthValues.scope]: localStorage.getItem(AuthValues.scope) || "",
});

export const AUTH_TYPE_OPTIONS: {
  label: string;
  value: AuthConfigTypeValues;
}[] = [
  {
    label: "No Auth",
    value: AuthConfigTypeValues.NoAuth,
  },
  {
    label: "Bearer Token",
    value: AuthConfigTypeValues.BearerToken,
  },
  {
    label: "OAuth 2.0",
    value: AuthConfigTypeValues.OAuth2,
  },
];

export const FORM_CONFIG_OAUTH = [
  {
    label: "Access Token URL",
    id: AuthValues.accessTokenUrl,
    isRequired: true,
    hint: "The endpoint for authentication server",
  },
  {
    label: "Client ID",
    id: AuthValues.clientId,
    isRequired: true,
    hint:
      "The client identifier issued to the client during Application registration",
    warningText:
      "Warning: Client ID and Client Secret doesn't stored in secure way",
  },
  {
    label: "Client Secret",
    id: AuthValues.clientSecret,
    isRequired: true,
    hint:
      "The client identifier issued to the client during Application registration",
    warningText:
      "Warning: Client ID and Client Secret doesn't stored in secure way",
  },
  {
    label: "Scope",
    id: AuthValues.scope,
    isRequired: false,
    hint:
      "The scope of the access request. For multiply values use space delimiter",
  },
];
