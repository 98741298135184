import Monaco from "monaco-editor";
import { useCallback, useContext } from "react";

import { useEditor, useAlert, useLoader } from "../../../hooks";
import { forceTypeCast } from "../../../types";
import { useDiagnostic } from "./useDiagnostic";
import {
  FHIR_VALIDATE_COMMAND,
  FHIR_EXPAND_COMMAND,
  FHIR_LOAD_BASE_DEFINITION,
  FHIR_SNAPSHOT_COMMAND,
  fhirValidate,
  fhirSnapshot,
  fhirExpand,
  loadBaseDefinition,
  FHIR_SEND,
  fhirSend,
} from "../../../commands";
import { AppContext } from "../../../context/AppContext";

export const useCommands = () => {
  const { editorRef, replaceEditorContent } = useEditor();
  const { setExpandContent } = useContext(AppContext);

  const { toggle: toggleAlert } = useAlert();

  const { toggle: toggleLoader } = useLoader();

  const { setDiagnostics } = useDiagnostic();

  const onMount = useCallback(
    (editor: Monaco.editor.IStandaloneCodeEditor) => {
      editor.addAction(
        forceTypeCast<Monaco.editor.IActionDescriptor>({
          id: FHIR_VALIDATE_COMMAND,
          label: "FHIR validate resource",
          contextMenuGroupId: "FHIR",
          run: fhirValidate(toggleLoader, toggleAlert, setDiagnostics),
        })
      );
      editor.addAction({
        id: FHIR_SNAPSHOT_COMMAND,
        label: "FHIR create snapshot",
        contextMenuGroupId: "FHIR",
        run: fhirSnapshot(toggleLoader, toggleAlert, replaceEditorContent),
      });
      editor.addAction({
        id: FHIR_EXPAND_COMMAND,
        label: "FHIR expand value set",
        contextMenuGroupId: "FHIR",
        run: fhirExpand(toggleLoader, toggleAlert, setExpandContent),
      });
      editor.addAction({
        id: FHIR_LOAD_BASE_DEFINITION,
        label: "FHIR Load Base definition",
        contextMenuGroupId: "FHIR",
        run: loadBaseDefinition(
          toggleLoader,
          toggleAlert,
          replaceEditorContent
        ),
      });
      editor.addAction({
        id: FHIR_SEND,
        label: "FHIR Send to FHIR server",
        contextMenuGroupId: "FHIR",
        run: fhirSend(toggleLoader, toggleAlert, setDiagnostics),
      });
      editorRef.current = editor;
    },
    [
      editorRef,
      toggleAlert,
      toggleLoader,
      replaceEditorContent,
      setExpandContent,
      setDiagnostics,
    ]
  );

  return { onMount };
};
