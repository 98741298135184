import { useState, useCallback } from "react";

type UseContextMenuParams = {
  defaultValue: string;
  setTarget: (id: string) => void;
};

export const useContextMenu = ({
  defaultValue,
  setTarget
}: UseContextMenuParams) => {
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const handleContextMenu = useCallback((event: React.MouseEvent) => {
    event.preventDefault();

    const target = event.target as Element;

    const { parentElement: wrapper } = target;

    const { id: itemId = defaultValue } = wrapper?.parentElement?.parentElement?.dataset ?? {};

    setTarget(itemId);
    
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  }, [defaultValue, contextMenu, setTarget, setContextMenu]);

  return {
    contextMenu,
    handleContextMenu,
    closeContextMenu
  };
};
