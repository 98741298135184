import type Monaco from "monaco-editor";

import { AlertType } from "@profiler/ui";

import { makeRequest } from "../api/request";
import { AlertData } from "../types";
import { ConfigUrlsTypes } from "../types/configUrls";
import { handleResponse } from "./handleResponse";

export const FHIR_SNAPSHOT_COMMAND = "fhir:snapshot";

const getSnapshotUrl = (resourceType: string) => {
  return `${localStorage.getItem(
    ConfigUrlsTypes.FhirBaseApiUrl
  )}/${resourceType}/$snapshot`;
};

export const fhirSnapshot = (
  toggleLoader: () => void,
  toggleAlert: (data: AlertData) => void,
  replaceContent: (text: string) => void
) => {
  return async (editor: Monaco.editor.ICodeEditor) => {
    toggleLoader();
    try {
      const model = editor.getModel() as Monaco.editor.ITextModel;
      const editorText = model.getValue();
      const { snapshot: _snapshot, ...structure } = JSON.parse(editorText);

      if (structure.resourceType !== "StructureDefinition") {
        throw new Error(
          "Snapshot generation is available only for StructureDefinition"
        );
      }

      const url = getSnapshotUrl(structure?.resourceType);
      const response = await makeRequest({ url, body: structure });

      if (handleResponse(response, toggleAlert) && response.ok) {
        replaceContent(JSON.stringify(response.body, null, 2));

        toggleAlert({
          message: "snapshot was successfully created ",
          type: AlertType.Success,
        });
      }
    } catch (error) {
      toggleAlert({
        message: (error as { message: string }).message,
        type: AlertType.Error,
      });
    } finally {
      toggleLoader();
    }
  };
};
