import { Flexbox, Hint } from "@profiler/ui";

import { CONTENT_HEIGHT } from "../constants";
import { HINTS, HintsId, useHints } from "../hooks";
import { Sidebar } from "./Sidebar";
import { MonacoEditor } from "./MonacoEditor";
import { ProfileView } from "./ProfileView";

export const Content = () => {
  const { hintId, ...hintProps } = useHints();

  return (
    <Flexbox flexFlow="row nowrap" justifyContent="center" alignItems="center">
      <Sidebar />
      <Flexbox
        flexGrow={1}
        color="text"
        bg="base"
        minHeight={CONTENT_HEIGHT}
        maxHeight={CONTENT_HEIGHT}
      >
        <MonacoEditor />
        <ProfileView />
      </Flexbox>

      {hintId && <Hint hint={HINTS[hintId as HintsId]} {...hintProps} />}
    </Flexbox>
  );
};
