import debounce from "lodash/debounce";
import { useCallback } from "react";

import { FileSystemActionType } from "../../../types";
import { useFileSystem, useEditor } from "../../../hooks";
import { EMPTY_PROFILE } from "../../../constants";

export const useModelChange = () => {
  const { replaceEditorContent } = useEditor();

  const { dispatch, activeFileId } = useFileSystem();

  const actionChange = useCallback((content: string) => {
    dispatch({ 
      type: FileSystemActionType.Change, 
      payload: {
        id: activeFileId,
        content
      } 
    });
  }, [activeFileId, dispatch]);

  const onChange = useCallback((text) => {
    if (text === "") {
      replaceEditorContent(EMPTY_PROFILE);
      actionChange(EMPTY_PROFILE);
    } else {
      actionChange(text);
    }
  }, [replaceEditorContent, actionChange]);

  return { onChange: debounce(onChange, 1000) };
};
