import Monaco from "monaco-editor";
import { useState, useEffect, useMemo } from "react";
import { useMonaco } from "@monaco-editor/react";

import { useFileSystem, useEditor } from "../../../hooks";
import { selectProfile } from "../../../context/file-system";

export const useDiagnostic = () => {
  const monaco = useMonaco();

  const { editorRef } = useEditor();

  const { activeFile } = useFileSystem();

  const [diagnostics, setDiagnostics] = useState<Monaco.editor.IMarkerData[]>([]);

  const profile = useMemo(
    () => selectProfile(activeFile), 
    [activeFile]
  );

  useEffect(() => {
    if (monaco) {
      const model = editorRef.current.getModel();
      model && monaco.editor.setModelMarkers(model, "fhir", diagnostics);
    }
  }, [monaco, editorRef, diagnostics]);

  // Reset diagnostic when profile id changes.
  // TODO: preserve diagnostic information between files.
  useEffect(() => {    
    if (profile?.id) setDiagnostics([]);
  }, [profile?.id, setDiagnostics]);

  return { diagnostics, setDiagnostics };
};
