import { FileSystemStorage, FileSystem, PaletteMode } from "../types";

enum StorageItem {
  FileSystem = "fhirpt-file-system",
  PaletteMode = "fhirpt-palette-mode"
};

const getFileSystem = (): FileSystemStorage => {
  const fileSystemStorage = localStorage.getItem(StorageItem.FileSystem);

  return fileSystemStorage ? JSON.parse(fileSystemStorage) : null;
};

const setFileSystem = (fileSystem: FileSystem) => {
  localStorage.setItem(StorageItem.FileSystem, JSON.stringify(fileSystem));
};

const getPaletteMode = (): PaletteMode | null => {
  return localStorage.getItem(StorageItem.PaletteMode) as PaletteMode;
};

const setPaletteMode = (mode: PaletteMode) => {
  localStorage.setItem(StorageItem.PaletteMode, mode);
};

export {
  getFileSystem,
  setFileSystem,
  getPaletteMode,
  setPaletteMode
};
