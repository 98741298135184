import React, {
  useMemo,
  useCallback,
  SyntheticEvent,
  memo,
  useState,
} from "react";
import { Box } from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab";
import {
  ExpandMore,
  ChevronRight,
  Folder,
  TextSnippet,
} from "@mui/icons-material";

import {
  TreeNode,
  isFile,
  isFolder,
  FileSystemActionType,
  FileSystem,
  FileSystemState,
  FileSystemAction,
} from "../../../types";
import { useFileSystem } from "../../../hooks";
import { selectTree, selectFoldersIds } from "../../../context/file-system";
import { FilesContextMenu } from "./FilesContextMenu";
import { FilesTopMenu } from "./FilesTopMenu";

const TreeRenderer = ({ nodes }: { nodes: TreeNode }) => (
  <TreeItem
    draggable
    nodeId={nodes.id}
    label={
      <Box sx={{ display: "flex", alignItems: "center", p: "2px" }}>
        {isFolder(nodes) && (
          <Box
            component={Folder}
            color="inherit"
            sx={{ mr: 1, ml: -1, fontSize: 18 }}
          />
        )}
        {nodes.name}
      </Box>
    }
    data-id={nodes.id}
    icon={isFile(nodes) ? <TextSnippet /> : null}
  >
    {Array.isArray(nodes.children)
      ? nodes.children.map((node) => (
          <TreeRenderer key={node.id} nodes={node} />
        ))
      : null}
  </TreeItem>
);

export const Files = () => {
  const {
    fileSystem,
    fileSystemSnapshot,
    activeFileId,
    dispatch,
  } = useFileSystem();

  return (
    <FilesContent
      fileSystem={fileSystem}
      fileSystemSnapshot={fileSystemSnapshot}
      activeFileId={activeFileId}
      dispatch={dispatch}
    />
  );
};

const FilesContent = memo(
  ({
    fileSystem,
    fileSystemSnapshot,
    activeFileId,
    dispatch,
  }: {
    fileSystem: FileSystem;
    fileSystemSnapshot: FileSystemState;
    activeFileId: string;
    dispatch: React.Dispatch<FileSystemAction>;
  }) => {
    const [targetItemId, setTargetItemId] = useState<string | null>(null);
    const [filter, setFilter] = useState<string>();
    const tree = useMemo(() => selectTree(fileSystem, filter), [
      fileSystem,
      filter,
    ]);

    const folders = useMemo(
      () => selectFoldersIds(fileSystemSnapshot.fileSystem),
      [fileSystemSnapshot]
    );

    const onNodeSelect = useCallback(
      (_event: SyntheticEvent, value: string) => {
        setTargetItemId(value);

        if (isFile(fileSystem[value])) {
          dispatch({
            type: FileSystemActionType.SetActive,
            payload: {
              id: value,
            },
          });
        }
      },
      [fileSystem, dispatch]
    );

    const onFilterChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setFilter(e.target.value);
      },
      [setFilter]
    );

    return (
      <>
        <FilesTopMenu
          targetItemId={targetItemId}
          onFilterChange={onFilterChange}
        />
        <FilesContextMenu
          targetItemId={targetItemId}
          setTargetItemId={setTargetItemId}
        >
          <TreeView
            aria-label="rich object"
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            defaultExpanded={folders}
            selected={activeFileId}
            onNodeSelect={onNodeSelect}
            sx={{
              flexGrow: 1,
              maxWidth: 250,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {tree.map((rootNode) => (
              <TreeRenderer key={rootNode.id} nodes={rootNode} />
            ))}
          </TreeView>
        </FilesContextMenu>
      </>
    );
  }
);
