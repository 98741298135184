import React, { useCallback } from "react";
import { DeleteOutline } from "@mui/icons-material";

import { useFileSystem, useDialog } from "../../../hooks";
import { FileSystemActionType } from "../../../types";
import { UseMenuItemParams, UseMenuItemResult } from "../types";
import { useTargetItem } from "./useTargetItem";

export const useRemoveItem = ({
  targetItemId,
  closeMenu,
}: UseMenuItemParams): UseMenuItemResult => {
  const { dispatch } = useFileSystem();
  const { isRoot, targetItem } = useTargetItem({ targetItemId });
  const { getSubmitted } = useDialog();

  const onRemove = useCallback(async () => {
    closeMenu();
    if (!targetItemId || !targetItem) return;

    const result = await getSubmitted({
      title: "Remove",
      description: `Are you sure you want to remove ${targetItem.name}?`,
      okText: "Yes",
    });

    if (result) {
      dispatch({
        type: FileSystemActionType.Remove,
        payload: {
          id: targetItemId,
        },
      });
    }
  }, [targetItemId, targetItem, closeMenu, getSubmitted, dispatch]);

  const onRemoveAll = useCallback(async () => {
    closeMenu();

    const result = await getSubmitted({
      title: "Remove all",
      description: `Are you sure you want to remove all files and folders?`,
      okText: "Yes",
    });

    if (result) {
      dispatch({
        type: FileSystemActionType.RemoveAll,
        payload: {},
      });
    }
  }, [closeMenu, getSubmitted, dispatch]);

  return {
    available: true,
    name: isRoot ? "Remove all" : "Remove",
    icon: <DeleteOutline />,
    onClick: isRoot ? onRemoveAll : onRemove,
  };
};
