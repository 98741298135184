import {
  formatServerError,
  getIssueBySeverity,
  IssueBySeverity,
  messageFromIssues,
  Severity,
  VALIDATOR_OK_STATUSES,
} from "@profiler/diagnostic";
import { AlertType } from "@profiler/ui";
import { AlertData } from "../types";

const alertTypeFromIssues = (issueBySeverity: IssueBySeverity): AlertType => {
  if (
    issueBySeverity[Severity.Fatal].length ||
    issueBySeverity[Severity.Error].length
  ) {
    return AlertType.Error;
  }
  if (issueBySeverity[Severity.Warning].length) {
    return AlertType.Info;
  }

  return AlertType.Success;
};

export const handleResponse = (
  response: any,
  toggleAlert: (data: AlertData) => void
): boolean => {
  if (
    !response.body?.resourceType ||
    !VALIDATOR_OK_STATUSES.includes(response.status)
  ) {
    throw new Error(
      formatServerError(
        response.url,
        response.status,
        response.statusText || JSON.stringify(response.body)
      )
    );
  }

  const issueBySeverity = getIssueBySeverity(response.body?.issue);
  toggleAlert({
    message: messageFromIssues(issueBySeverity),
    type: alertTypeFromIssues(issueBySeverity),
  });

  return true;
};
