import { useEffect, useState } from "react";

const HINTS_FLAG_KEY = "hints_have_been_shown";

export const useHints = () => {
  const hintsHaveBeenShown = localStorage.getItem(HINTS_FLAG_KEY);
  const defaultIndex = hintsHaveBeenShown ? undefined : 0;
  const [hintIndex, setHintIndex] = useState<number | undefined>(defaultIndex);

  useEffect(() => {
    if (hintIndex === undefined) {
      localStorage.setItem(HINTS_FLAG_KEY, "true");
    }
    if (hintIndex === 0) {
      localStorage.setItem(HINTS_FLAG_KEY, "");
    }
  }, [hintIndex]);

  return { hintIndex, setHintIndex };
};
