import type Monaco from "monaco-editor";

import { StructureDefinition } from "@profiler/fhir-base";

import { findPositions } from "../../utils";
import { FileSystemAction, FileSystemActionType } from "../../types";

export const setLinkProvider = (
  monaco: typeof Monaco, 
  editor: Monaco.editor.IStandaloneCodeEditor,
  dispatch: React.Dispatch<FileSystemAction>
) => {
  monaco.languages.registerLinkProvider("json", {
    provideLinks(model) {
      try {
        const profile: StructureDefinition = JSON.parse(model.getValue());

        const { element: snapshotElements = [] } = profile.snapshot || {};

        const { element: differentialElements = [] } = profile.differential || {};

        const elements = snapshotElements.concat(differentialElements);

        const urls = new Set(elements.reduce<string[]>((result, element) => {
          element.type?.forEach((type) => {
            // element.type.extension.url
            type.extension?.forEach((extension) => {
              extension.url && result.push(extension.url);
            });

            // element.type.targetProfile
            type.targetProfile?.forEach((targetProfile) => {
              result.push(targetProfile);
            });

            // element.type.profile
            type.profile?.forEach((profile) => {
              result.push(profile);
            });
          });

          // element.binding.valueSet
          element.binding?.valueSet && result.push(element.binding.valueSet);
          
          return result;
        }, []));

        const links = Array.from(urls).reduce<Monaco.languages.ILink[]>((result, url) => {
          const baseModel = monaco.editor.getModels().find((item) => {
            const itemProfile = JSON.parse(item.getValue());
            return itemProfile.url === url;
          });

          if (baseModel) {
            const positions = findPositions(model, url);

            positions.forEach((position) => {
              const { lineNumber, column } = position;
    
              const range = new monaco.Range(
                lineNumber,
                column,
                lineNumber,
                (model?.getLineLastNonWhitespaceColumn(lineNumber) || column) - 2
              );
    
              result.push({
                range,
                tooltip: baseModel.uri.path,
              });
            });
          }

          return result;
        }, []);
        
        return { links };
      } catch {}
    },
    resolveLink(link) {
      const baseModel = monaco.editor.getModels().find(
        (model) => model.uri.path === link.tooltip
      );

      if (baseModel) {
        editor.setModel(baseModel);

        dispatch({
          type: FileSystemActionType.SetActive,
          payload: {
            id: baseModel.uri.path
          }
        });
      }

      return link;
    }
  });
};
