import type Monaco from "monaco-editor";
import { ConfigSnippetValues } from "../../types/configUrls";

enum ResourceType {
  StructureDefinition = "StructureDefinition",
  ValueSet = "ValueSet",
  CodeSystem = "CodeSystem",
  ConceptMap = "ConceptMap",
}
type SnippetDefaultValues = Record<keyof typeof ConfigSnippetValues, string>

const getSnippetTemplate = (resourceType: ResourceType, defaultValues: SnippetDefaultValues) => {
  const snippetTemplates = {
    [ResourceType.StructureDefinition]: `"resourceType": "StructureDefinition",
"id": "",
"url": "",
"version": "${defaultValues.version || "0.01"}",
"name": "",
"title": "",
"status": "${defaultValues.status || "draft"}",
"experimental": true,
"date": "${defaultValues.date}",
"publisher": "${defaultValues.publisher}",
"fhirVersion": "${defaultValues.fhirVersion}",
"kind": "resource",
"abstract": false,
"derivation": "specialization",
"type": "",
"baseDefinition": ""`,
    [ResourceType.ValueSet]: `"resourceType": "ValueSet",
"id": "",
"url": "",
"version": "${defaultValues.version || "0.01"}",
"name": "",
"title": "",
"status": "${defaultValues.status || "draft"}",
"experimental": true,
"date": "${defaultValues.date}",
"publisher": "${defaultValues.publisher}",
"description": "",
"compose": {
    "include": [
        {
            "system": ""
        }
    ]
}`,
    [ResourceType.CodeSystem]: `"resourceType": "CodeSystem",
"id": "",
"url": "",
"version": "${defaultValues.version || "0.01"}",
"name": "",
"title": "",
"status": "${defaultValues.status || "draft"}",
"experimental": true,
"publisher": "${defaultValues.publisher}",
"description": "",
"caseSensitive": false,
"content": "example",
"concept": [
    {
        "code": "",
        "display": "",
        "definition": ""
    }
]`,
    [ResourceType.ConceptMap]: `"resourceType": "ConceptMap",
"id": "",
"url": "",
"version": "${defaultValues.version}",
"name": "",
"status": "${defaultValues.status || "draft"}",
"experimental": true,
"date": "${defaultValues.date}",
"publisher": "${defaultValues.publisher}",
"description": "",
"sourceCanonical": "",
"targetCanonical": "",
"group": [
  {
    "source": "",
    "sourceVersion": "",
    "target": "",
    "targetVersion": "",
    "element": [
      {
        "code": "",
        "target": [
          {
            "code": "",
            "equivalence": ""
          }
        ]
      }
    ]
  }
]`
  };
  return snippetTemplates[resourceType];
}

const getSnippetDefaultValues = () => Object.fromEntries(Object.entries(ConfigSnippetValues)
  .map(([key, value]) => {
    return [key, localStorage.getItem(value) || ""];
  })) as SnippetDefaultValues;

export const setCompletions = (monaco: typeof Monaco) => {
  monaco.languages.registerCompletionItemProvider("json", {
    provideCompletionItems: (model, position) => {
      const word = model.getWordUntilPosition(position);

      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn
      };
      const snippetDefaultValues = getSnippetDefaultValues();

      return {
        suggestions: [
          {
            label: `"resourceType": "StructureDefinition"`,
            kind: monaco.languages.CompletionItemKind.Snippet,
            documentation: "Add StructureDefinition template",
            insertText: getSnippetTemplate(ResourceType.StructureDefinition, snippetDefaultValues),
            range: range
          },
          {
            label: `"resourceType": "ValueSet"`,
            kind: monaco.languages.CompletionItemKind.Snippet,
            documentation: "Add ValueSet template",
            insertText: getSnippetTemplate(ResourceType.ValueSet, snippetDefaultValues),
            range: range
          },
          {
            label: `"resourceType": "CodeSystem"`,
            kind: monaco.languages.CompletionItemKind.Snippet,
            documentation: "Add CodeSystem template",
            insertText: getSnippetTemplate(ResourceType.CodeSystem, snippetDefaultValues),
            range: range
          },
          {
            label: `"resourceType": "ConceptMap"`,
            kind: monaco.languages.CompletionItemKind.Snippet,
            documentation: "Add ConceptMap template",
            insertText: getSnippetTemplate(ResourceType.ConceptMap, snippetDefaultValues),
            range: range
          },
        ]
      };
    }
  });
};
