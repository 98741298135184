import { useMemo, useState, useEffect } from "react";
import { createTheme } from "@mui/material";

import { getUiTheme } from "@profiler/ui";

import * as storage from "../api/storage";
import { PaletteMode } from "../types";
import { COLOR_SCHEMA } from "../constants";

const defaultMode = storage.getPaletteMode() ?? "dark";

export const useTheme = () => {
  const [mode, setMode] = useState<PaletteMode>(defaultMode);
  
  const styled = useMemo(() => createTheme({
    ...getUiTheme(COLOR_SCHEMA[mode]),
    palette: {
      mode,
      custom: COLOR_SCHEMA[mode]
    }
  }), [mode]);

  useEffect(() => {
    storage.setPaletteMode(mode);
  }, [mode]);

  return {
    mode,
    setMode,
    styled
  };
};
