import React from "react";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";

import { ROOT_FOLDER } from "../../../constants";
import { useContextMenu, useMenuItems } from "../hooks";

type FilesContextMenuProps = {
  targetItemId: string | null;
  setTargetItemId: (value: string | null) => void;
  children?: React.ReactNode;
}

export const FilesContextMenu = ({ targetItemId, setTargetItemId, children }: FilesContextMenuProps) => {
  const {
    contextMenu,
    handleContextMenu,
    closeContextMenu
  } = useContextMenu({ 
    defaultValue: ROOT_FOLDER, 
    setTarget: setTargetItemId 
  });

  const menuItems = useMenuItems({
    targetItemId,
    closeMenu: closeContextMenu
  });

  return (
    <div
      onContextMenu={handleContextMenu}
      style={{
        cursor: "context-menu",
        height: "100%"
      }}
    >
      {children}
      <Menu
        open={contextMenu !== null}
        onClose={closeContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {menuItems.map((item) => {
          const { available, name, icon, ...itemProps } = item;
          return available ? (
            <MenuItem key={name} {...itemProps}>
              {icon && <ListItemIcon>
                {icon}
              </ListItemIcon>}
              {name}
            </MenuItem>
          ) : null;
        })}
      </Menu>
    </div>
  );
};
