import { useCallback } from "react";
import { Alert as UIAlert } from "@profiler/ui";
import { useAlert } from "../hooks";

export const Alert = () => {
  const { data, toggle } = useAlert();

  const hide = useCallback(() => {
    toggle();
  }, [toggle]);

  return data ? (
    <UIAlert message={data.message} type={data.type} onClose={hide} />
  ) : null;
};
