import Monaco from "monaco-editor";

import { AlertType } from "@profiler/ui";
import { AlertData, ITextModel } from "../types";
import { makeRequest } from "../api/request";
import { ConfigUrlsTypes } from "../types/configUrls";

export const FHIR_LOAD_BASE_DEFINITION = "fhir:loadBaseDefinition";

const getLoadBaseDefinitionUrl = (
  resourceType: string,
  baseDefinitionUrl: string
) => {
  return `${localStorage.getItem(
    ConfigUrlsTypes.FhirBaseApiUrl
  )}/${resourceType}?url=${baseDefinitionUrl}`;
};

export const loadBaseDefinition = (
  toggleLoader: () => void,
  toggleAlert: (data: AlertData) => void,
  replaceContent: (text: string) => void
) => {
  return async (editor: Monaco.editor.ICodeEditor): Promise<any> => {
    toggleLoader();

    try {
      const model = editor.getModel() as ITextModel;

      const editorText = model.getValue();

      const structure = JSON.parse(editorText);

      const resourceType = structure.resourceType;
      const baseDefinitionUrl = structure?.baseDefinition;

      if (resourceType !== "StructureDefinition") {
        throw new Error(
          "Load base definition is available only for StructureDefinition"
        );
      }

      if (!baseDefinitionUrl) {
        throw new Error(
          "Load base definition is available only when baseDefinition is not empty"
        );
      }

      const url = getLoadBaseDefinitionUrl(resourceType, baseDefinitionUrl);
      const bundle = await makeRequest({ url, method: "GET" });
      const [response] = bundle?.body?.entry || [];

      if (response) {
        structure.snapshot = response?.resource?.snapshot;
        replaceContent(JSON.stringify(structure, null, 2));
      } else {
        throw new Error(
          "StructureDefinition was not found by this baseDefinition"
        );
      }
    } catch (error) {
      toggleAlert({
        message: (error as { message: string }).message,
        type: AlertType.Error,
      });
    } finally {
      toggleLoader();
    }
  };
};
