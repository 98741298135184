import React from "react";
import { CreateNewFolderOutlined, NoteAddOutlined } from "@mui/icons-material";

import { InputBase, Tooltip } from "@mui/material";
import { Flexbox, SvgButton } from "@profiler/ui";

import { ROOT_FOLDER } from "../../../constants";
import { isFile } from "../../../types";
import { useFileSystem } from "../../../hooks";
import { useAddFileItem, useAddFolderItem } from "../hooks";

const closeMenu = () => {
  return null;
};

type Props = {
  targetItemId: string | null;
  onFilterChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const FilesTopMenu = ({
  targetItemId: parentTargetItemId,
  onFilterChange,
}: Props) => {
  const { fileSystem } = useFileSystem();

  const parentFolder =
    (parentTargetItemId && isFile(fileSystem[parentTargetItemId])
      ? parentTargetItemId.split("/").slice(0, -1).join("/")
      : parentTargetItemId) || ROOT_FOLDER;

  const { onClick: onAddFileClick, name: addFileName } = useAddFileItem({
    targetItemId: parentFolder,
    closeMenu,
  });
  const { onClick: onAddFolderClick, name: addFolderName } = useAddFolderItem({
    targetItemId: parentFolder,
    closeMenu,
  });

  return (
    <Flexbox
      flexFlow="row nowrap"
      justifyContent="end"
      alignItems="center"
      paddingTop={1}
      paddingLeft={2}
      paddingRight={2}
      paddingBottom={1}
      marginBottom={2}
      borderBottom={1}
      borderColor="divider"
    >
      <InputBase
        sx={{ ml: 0, flex: 1, mr: 1 }}
        placeholder="Filter..."
        type="search"
        onChange={onFilterChange}
      />

      <Tooltip title={addFileName}>
        <SvgButton
          padding={0}
          backgroundColor="transparent"
          ml={2}
          onClick={onAddFileClick}
        >
          <NoteAddOutlined />
        </SvgButton>
      </Tooltip>

      <Tooltip title={addFolderName}>
        <SvgButton
          padding={0}
          backgroundColor="transparent"
          ml={2}
          onClick={onAddFolderClick}
        >
          <CreateNewFolderOutlined />
        </SvgButton>
      </Tooltip>
    </Flexbox>
  );
};
