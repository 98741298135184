import React, { useCallback } from "react";
import { NoteAddOutlined } from "@mui/icons-material";

import { useFileSystem, useDialog } from "../../../hooks";
import { useTargetItem } from "./useTargetItem";
import { FileSystemActionType } from "../../../types";
import { UseMenuItemParams, UseMenuItemResult } from "../types";
import { validateName } from "../validate";

export const useAddFileItem = ({
  targetItemId,
  closeMenu
}: UseMenuItemParams): UseMenuItemResult => {
  const { dispatch, fileSystem } = useFileSystem();

  const { isFolder } = useTargetItem({ targetItemId });

  const { getSubmitted } = useDialog();

  const onAddFile = useCallback(async () => {
    closeMenu();

    if (!targetItemId) return;

    const result = await getSubmitted({
      title: `Add file to ${targetItemId}`,
      fields: [{
        name: "name",
        required: true,
        validate: validateName({ targetItemId, fileSystem })
      }]
    });
    
    if (typeof result === "object" && result.name) {
      dispatch({
        type: FileSystemActionType.AddFile,
        payload: {
          parentId: targetItemId,
          name: result.name
        }
      });
    }
  }, [
    targetItemId,
    fileSystem,
    closeMenu,
    getSubmitted,
    dispatch
  ]);

  return {
    available: isFolder,
    name: "Add file",
    icon: <NoteAddOutlined />,
    onClick: onAddFile
  };
};