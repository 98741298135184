import { 
  useState, 
  useCallback,
  useEffect, 
  useRef, 
  MutableRefObject, 
  MouseEvent 
} from "react"

import { forceTypeCast } from "../types";

export const useResize = () => {
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [position, setPosition] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const onResize = useCallback((event: MouseEvent<HTMLDivElement>) => {
    if (!ref.current) return;
    setPosition(event.clientX);
    setWidth(ref.current.clientWidth);
    setIsResizing(true);
  }, [setWidth, setPosition]);

  const onDrag = useCallback((ev: Event) => {
    if (!ref.current) return;    
    // Calculate the box width.
    const event = forceTypeCast<MouseEvent<HTMLDivElement>>(ev);
    const currentWidth = Math.ceil(width + event.clientX - position);
    ref.current.style.minWidth = currentWidth + "px";
  }, [width, position]);

  const onDragStop = useCallback(() => {
    document.removeEventListener("mousemove", onDrag);
    document.removeEventListener("mouseup", onDragStop);
    setIsResizing(false);
  }, [onDrag, setIsResizing]);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener("mousemove", onDrag);
      document.addEventListener("mouseup", onDragStop);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResizing]);

  return { ref, onResize };
};
