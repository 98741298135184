import type Monaco from "monaco-editor";

import fhirSchema from "@profiler/fhir-base/schema/fhir.schema.json";

export const setJsonSchema = (
  monaco: typeof Monaco
) => {
  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: true,
    schemas: [{
      uri: "http://json-schema.org/draft-06/schema",
      fileMatch: ["*.fhir.json"],
      schema: fhirSchema
    }]
  })
};