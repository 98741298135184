import React, { useState } from "react";
import { ThemeProvider } from "styled-components";

import { AppContextType, ExpandContentType } from "../types";
import { useAlert } from "./useAlert";
import { useLoader } from "./useLoader";
import { useDialog } from "./useDialog";
import { useEditor } from "./useEditor";
import { useFileSystem } from "./file-system/useFileSystem";
import { useTheme } from "./useTheme";
import { useHints } from "./useHints";
import { Alert } from "../components/Alert";
import { Dialog } from "../components/Dialog";

export const AppContext = React.createContext<AppContextType>({} as AppContextType);

const { Provider } = AppContext;

export const AppProvider = ({ children }: { children: React.ReactNode[] }) => {
  const [expandContent, setExpandContent] = useState<ExpandContentType>({});

  const alert = useAlert();
  const loader = useLoader();
  const dialog = useDialog();
  const editorRef = useEditor();
  const fileSystem = useFileSystem();
  const theme = useTheme();
  const hints = useHints();

  return ( 
    <ThemeProvider theme={theme.styled}>
      <Provider value={{ alert, loader, dialog, editorRef, fileSystem, theme, setExpandContent, expandContent, hints }}>
        <Alert />
        {dialog.data && <Dialog />}
        {children}
      </Provider>
    </ThemeProvider>
  );
};
