import Monaco from "monaco-editor";
import {
  getIssueBySeverity,
  messageFromIssues,
  VALIDATOR_OK_STATUSES,
} from "@profiler/diagnostic";
import { AlertType } from "@profiler/ui";

import { AlertData, ITextModel } from "../types";
import { setValidationResult } from "./validate";
import { ConfigUrlsTypes } from "../types/configUrls";
import { makeRequest } from "../api/request";

export const FHIR_SEND = "fhir:fhirSend";
const RESOURCE_CREATED = 201;

const getFhirSendUrl = (resourceType: string) => {
  return `${localStorage.getItem(
    ConfigUrlsTypes.FhirBaseApiUrl
  )}/${resourceType}`;
};

export const fhirSend = (
  toggleLoader: () => void,
  toggleAlert: (data: AlertData) => void,
  setDiagnostics: (diagnostics: Monaco.editor.IMarkerData[]) => void
) => {
  return async (editor: Monaco.editor.ICodeEditor) => {
    toggleLoader();
    try {
      const model = editor.getModel() as ITextModel;

      const editorText = model.getValue();

      const structure = JSON.parse(editorText);

      if (structure.resourceType !== "StructureDefinition") {
        throw new Error(
          "FHIR Send to FHIR server action is available only for StructureDefinition"
        );
      }

      const url = getFhirSendUrl(structure.resourceType);
      const response = await makeRequest({ url, body: structure });

      if (VALIDATOR_OK_STATUSES.includes(response.status)) {
        setValidationResult(model, response?.body.issue, setDiagnostics);
        const issueBySeverity = getIssueBySeverity(response.body?.issue);
        throw new Error(messageFromIssues(issueBySeverity));
      }

      if (response.status === RESOURCE_CREATED) {
        toggleAlert({
          message: "StructureDefinition was created successfully",
          type: AlertType.Success,
        });
      }
    } catch (error) {
      toggleAlert({
        message: (error as { message: string }).message,
        type: AlertType.Error,
      });
    } finally {
      toggleLoader();
    }
  };
};
