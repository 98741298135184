import { useEffect } from "react";
import { useMonaco } from "@monaco-editor/react";

import { selectActiveId } from "../../../context/file-system";
import { setJsonSchema, setCompletions, setLinkProvider } from "../../../commands";
import { useFileSystem, useEditor } from "../../../hooks";
import { isFile } from "../../../types";

export const useInitialization = () => {
  const monaco = useMonaco();

  const { editorRef } = useEditor();

  const { fileSystemSnapshot, dispatch } = useFileSystem();

  useEffect(() => {
    if (monaco) {      
      // Dispose default models.
      monaco.editor.getModels().forEach(model => model.dispose());

      const activeFileIdSnapshot = selectActiveId(fileSystemSnapshot.fileSystem);

      // Add a model for each of stored files.
      Object.entries(fileSystemSnapshot.fileSystem)
        ?.forEach(([id, item]) => {
          if (isFile(item)) {
            const model = monaco.editor.createModel(
              item.content, 
              "json", 
              new monaco.Uri().with({ path: id })
            );

            if (id === activeFileIdSnapshot) {
              editorRef.current.setModel(model);
            }
          }
        });

      setJsonSchema(monaco);
      
      setCompletions(monaco);

      setLinkProvider(monaco, editorRef.current, dispatch);
    }
  }, [monaco, editorRef, fileSystemSnapshot, dispatch]);
};
