import { useContext } from "react";

import { HintData, HintProps } from "@profiler/ui";
import { AppContext } from "../context/AppContext";

export enum HintsId {
  Toolbar = "toolbar",
  MenuFiles = "sidebar-menu-files",
  MenuDocumentation = "sidebar-menu-documentation",
  MenuSettings = "sidebar-menu-settings",
  Editor = "editor",
  Tree = "tree",
  Tabs = "tabs",
  Themes = "themes",
}

export const HINTS: Record<HintsId, HintData> = {
  [HintsId.Toolbar]: {
    content:
      "Use toolbar functions to help with editing json-files. Upload and Download files in the project-tree.",
  },
  [HintsId.MenuFiles]: {
    content:
      "Use files tab to navigate your files in the project and manage folders.",
  },
  [HintsId.MenuDocumentation]: {
    content:
      "Links to the Documentation and additional info about Kodjin Interoperability Suite.",
  },
  [HintsId.MenuSettings]: {
    content:
      "Editor settings. Configure your connection to FHIR server endpoints and security.",
  },
  [HintsId.Editor]: {
    content:
      "In the Editor view you can create profiles for FHIR resources - create snapshots from differential, validate. Edit Terminologies or Search Parameters or just work with json-files.",
    sx: { right: "-300px" },
  },
  [HintsId.Tree]: {
    content:
      "In the tree section you can see rendered tree-structure for FHIR objects - Structure Definitions and Terminologies.",
  },
  [HintsId.Tabs]: {
    content:
      "Use different Tabs to highlight most important for you while editing profiles, terminologies or sear parameters.",
  },
  [HintsId.Themes]: {
    content: "You can easily switch between dark and light themes.",
  },
};

const HINTS_IDS = Object.values(HintsId);

type UseHintsResponse = HintProps & {
  hintId: HintsId | undefined;
};

export const useHints = (): UseHintsResponse => {
  const {
    hints: { hintIndex, setHintIndex },
    theme,
  } = useContext(AppContext) || {};

  const disableHints = () => {
    setHintIndex(undefined);
  };

  const goToNextHint = () => {
    if (hintIndex === undefined) {
      return setHintIndex(0);
    }
    if (hintIndex >= HINTS_IDS.length - 1) {
      return disableHints();
    }
    setHintIndex((prevIndex) => Number(prevIndex) + 1);
  };

  const hintId = hintIndex === undefined ? undefined : HINTS_IDS[hintIndex];
  const isLast = hintIndex === HINTS_IDS.length - 1;
  const targetStyle = {
    zIndex: 9999,
    borderRadius: "4px",
    backgroundColor: (theme as any).styled?.colors?.base || "white",
    boxShadow: `3px 3px 14px ${
      theme.mode === "light" ? "rgba(0,0,0,0.5)" : "rgba(255,255,255,0.5)"
    }`,
  };

  return {
    hintId,
    goToNextHint,
    disableHints,
    isLast,
    targetStyle,
  };
};
