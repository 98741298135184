import TagManager from "react-gtm-module";
import { useEffect } from "react";

import { GlobalStyle } from "@profiler/ui";
import * as config from "./config";
import { AppProvider } from "./context/AppContext";
import { Header } from "./components/Header";
import { Content } from "./components/Content";
import { ConfigUrlsTypes, TerminologyBodyType } from "./types/configUrls";
import {
  DEFAULT_FHIR_BASE_API_URL,
  DEFAULT_TERMINOLOGY_URL,
  DEFAULT_TERMINOLOGY_VALIDATION_BODY_TYPE,
} from "./constants/configApi";

export const App = () => {
  useEffect(() => {
    if (config.get("NODE_ENV") !== "development") {
      TagManager.initialize({ gtmId: config.get("GTM_ID") });
    }
  }, []);

  useEffect(() => {
    const terminologyUrl = localStorage.getItem(ConfigUrlsTypes.TerminologyUrl);
    const fhirUrl = localStorage.getItem(ConfigUrlsTypes.FhirBaseApiUrl);
    const terminologyValidationBodyType = localStorage.getItem(
      TerminologyBodyType.TerminologyValidationBodyType
    );
    if (!terminologyUrl) {
      localStorage.setItem(
        ConfigUrlsTypes.TerminologyUrl,
        DEFAULT_TERMINOLOGY_URL
      );
    }
    if (!fhirUrl) {
      localStorage.setItem(
        ConfigUrlsTypes.FhirBaseApiUrl,
        DEFAULT_FHIR_BASE_API_URL
      );
    }
    if (!terminologyValidationBodyType) {
      localStorage.setItem(
        TerminologyBodyType.TerminologyValidationBodyType,
        DEFAULT_TERMINOLOGY_VALIDATION_BODY_TYPE
      );
    }
  }, []);

  return (
    <AppProvider>
      <GlobalStyle />
      <Header />
      <Content />
    </AppProvider>
  );
};
