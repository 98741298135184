import { useReducer, useRef, useEffect, useMemo } from "react";

import * as storage from "../../api/storage";
import { EMPTY_PROFILE, ROOT_FOLDER } from "../../constants";
import { fileSystemReducer } from "./reducer";
import { selectActiveId } from "./selectors";
import { forceTypeCast, File } from "../../types";

const fileSystemStorage = storage.getFileSystem();

const initialState = {
  fileSystem: fileSystemStorage ?? {
    "/Untitled.fhir.json": {
      path: ROOT_FOLDER,
      name: "Untitled.fhir.json",
      content: EMPTY_PROFILE,
      isActive: true
    }
  },
  diff: []
};

export const useFileSystem = () => {
  const [fileSystemState, dispatch] = useReducer(fileSystemReducer, initialState);

  const { fileSystem, diff } = fileSystemState;

  const fileSystemRef = useRef(fileSystemState);

  const activeFileId = useMemo(() => selectActiveId(fileSystem), [fileSystem]);

  // To track every content change (id is the same, but the object isn't).
  const activeFile = forceTypeCast<File>(fileSystem[activeFileId]);
  
  useEffect(() => {
    const onBeforeUnload = (event: Event) => {
      try {
        storage.setFileSystem(fileSystem);
      } catch {        
        // @ts-ignore
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [fileSystem]);

  return {
    fileSystemState,
    fileSystem,
    diff,
    dispatch,
    activeFileId,
    activeFile,
    fileSystemSnapshot: fileSystemRef.current
  };
};
