import { useEffect, useRef } from "react";

export const useEventListener = <K extends keyof WindowEventMap>(
  type: K, 
  handler: (event: WindowEventMap[K]) => void, 
  element = window
) => {
  const handlerRef = useRef<(event: WindowEventMap[K]) => void>(handler);

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const listener = (event: WindowEventMap[K]) => handlerRef.current(event);

    element.addEventListener(type, listener);

    return () => {
      element.removeEventListener(type, listener);
    };
  }, [type, element]);
};
