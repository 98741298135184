import { useState, useCallback, SyntheticEvent } from "react";
import {
  Box,
  Tabs,
  Tab,
  Paper,
  Tooltip,
  SwipeableDrawer,
} from "@mui/material";
import {
  Source,
  AccountBalance,
  Settings as SettingsIcon,
} from "@mui/icons-material";

import { CONTENT_HEIGHT, COLOR_SCHEMA } from "../../constants";
import { HintsId, useHints, useTheme } from "../../hooks";
import { Files } from "./Files";
import { Documentation } from "./Documentation";
import { Settings } from "../Settings";

const TABS: {
  component?: React.VFC;
  icon: React.VFC;
  title: string;
  key: string;
}[] = [
  {
    key: HintsId.MenuFiles,
    component: Files,
    icon: Source,
    title: "Files",
  },
  {
    key: HintsId.MenuDocumentation,
    component: Documentation,
    icon: AccountBalance,
    title: "Documentation",
  },
  {
    key: HintsId.MenuSettings,
    icon: SettingsIcon,
    title: "Settings",
  },
];

export const Sidebar = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [value, setValue] = useState<number | false>(false);

  const { mode } = useTheme();
  const { hintId, targetStyle } = useHints();

  const handleTabClick = useCallback(
    (event: SyntheticEvent) => {
      const {
        tabIndex,
      } = (event as SyntheticEvent<HTMLButtonElement>).currentTarget.dataset;
      if (tabIndex && parseInt(tabIndex) === value) setValue(false);
    },
    [value, setValue]
  );

  const TabComponent = typeof value === "number" && TABS[value].component;

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setIsOpenDrawer(open);
  };

  return (
    <Box
      display="flex"
      minHeight={CONTENT_HEIGHT}
      bgcolor={COLOR_SCHEMA[mode].base}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={(_event, newValue) => setValue(newValue)}
        sx={{
          borderRight: 1,
          borderColor: "divider",
          width: 50,
          "& .MuiButtonBase-root": {
            minWidth: 50,
          },
        }}
      >
        {TABS.map(({ icon: IconComponent, title, key }, index) => (
          <Tooltip title={title} placement="right" key={key}>
            <Tab
              data-tab-index={index}
              icon={<IconComponent />}
              onClick={key === HintsId.MenuSettings ? toggleDrawer(true) : handleTabClick}
              sx={key === hintId ? targetStyle : undefined}
            />
          </Tooltip>
        ))}
      </Tabs>

      <SwipeableDrawer
        anchor={"right"}
        open={isOpenDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Settings />
      </SwipeableDrawer>
      {TabComponent && (
        <Paper
          square
          sx={{
            width: 250,
            height: CONTENT_HEIGHT,
            borderRight: 1,
            borderColor: "divider",
          }}
        >
          <TabComponent />
        </Paper>
      )}
    </Box>
  );
};
