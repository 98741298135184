import type { Obj } from "@profiler/diagnostic";
import {
  AuthConfigTypeValues,
  AuthValues,
  TerminologyBodyTypeValues,
} from "../types/configUrls";

type RequestType = {
  url: string;
  body?: Obj;
  method?: string;
  headers?: Obj;
  bodyType?: string;
};

export const makeRequest = async ({
  bodyType,
  url,
  body,
  method = "POST",
  headers = {},
}: RequestType) => {
  const accessToken = getAccessToken();
  const options = getOptions({
    url,
    body,
    method,
    headers: { ...headers, ...accessToken },
    bodyType,
  });
  const res = await fetch(url, options);
  const { ok, status, statusText } = res;

  if (status === 401) {
    throw new Error(
      "Unauthorized. It lacks valid authentication credentials for the requested resource. Please, check settings"
    );
  }
  if (status === 403) {
    throw new Error(
      "Forbidden. You don't have permission to access. Please, check settings"
    );
  }

  const resBody = await res.json();

  return {
    ok,
    status,
    statusText,
    url: res.url,
    body: resBody,
  };
};

const getOptions = ({
  bodyType,
  body: requestData,
  method,
  headers,
}: RequestType) => {
  if (bodyType === TerminologyBodyTypeValues.FormData) {
    const body = new FormData();
    body.append("data", JSON.stringify(requestData));
    return {
      method,
      body,
    };
  } else {
    return {
      method: method,
      headers: new Headers({ "Content-type": "application/json", ...headers }),
      body: requestData ? JSON.stringify(requestData) : null,
    };
  }
};

const getAccessToken = () => {
  const authType = localStorage.getItem(AuthValues.authType);
  if (authType === AuthConfigTypeValues.BearerToken) {
    const token = localStorage.getItem(AuthValues.bearerToken);
    return {
      Authorization: `Bearer ${token}`,
    };
  }
  if (authType === AuthConfigTypeValues.OAuth2) {
    const token = localStorage.getItem(AuthValues.accessToken);
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  return {};
};
