import { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import InputAdornment from "@mui/material/InputAdornment";

import { AlertType, Text } from "@profiler/ui";

import { DefaultStateType } from "./index";
import { AUTH_TYPE_OPTIONS, FORM_CONFIG_OAUTH } from "./constants";
import { AuthConfigTypeValues, AuthValues } from "../../types/configUrls";
import { useAlert } from "../../hooks";

type AuthType = {
  state: DefaultStateType;
  onChange: (event: any) => void;
};
export const Auth = ({ state, onChange }: AuthType) => {
  const { access_token_url, client_secret, client_id, scope } = state;
  const [isNeedToRefreshToken, setIsNeedToRefreshToken] = useState(false);
  const [token, setToken] = useState("");
  const { toggle: toggleAlert } = useAlert();

  useEffect(() => {
    const accessToken = localStorage.getItem(AuthValues.accessToken);
    setToken(accessToken || "");
  }, []);

  useEffect(() => {
    if (token) {
      checkIsNeedToRefreshToken(
        setIsNeedToRefreshToken,
        access_token_url,
        client_secret,
        client_id,
        scope
      );
    }
  }, [access_token_url, client_secret, client_id, scope, token]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      };

      const body: { [key: string]: string } = {
        client_id: state[AuthValues.clientId],
        client_secret: state[AuthValues.clientSecret],
        grant_type: "client_credentials",
        scope: state[AuthValues.scope],
      };
      let formBody: any = [];
      for (const property in body) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      const res: any = await fetch(state[AuthValues.accessTokenUrl], {
        method: "POST",
        headers,
        body: formBody,
      });

      const response = await res.json();
      const accessToken = response.access_token;

      if (accessToken) {
        setIsNeedToRefreshToken(false);
        localStorage.setItem(AuthValues.accessToken, response.access_token);
        setToken(response.access_token);
      } else {
        toggleAlert({
          message: response.error_description,
          type: AlertType.Error,
        });
      }
    } catch (error) {
      toggleAlert({
        message: "Request failed. Please try again",
        type: AlertType.Error,
      });
    }
  };

  return (
    <Box mb={3} component="form" onSubmit={handleSubmit}>
      <Text fontSize={4} mb={2} color="text">
        Auth
      </Text>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginBottom: 4,
        }}
      >
        <InputLabel id="authType-label">Type</InputLabel>
        <Select
          labelId="authType-label"
          sx={{ width: "49%", input: { fontSize: 14 } }}
          size="small"
          id={AuthValues.authType}
          value={state[AuthValues.authType] || AuthConfigTypeValues.NoAuth}
          name={AuthValues.authType}
          onChange={onChange}
        >
          {AUTH_TYPE_OPTIONS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {state[AuthValues.authType] === AuthConfigTypeValues.OAuth2 ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: 2,
            }}
          >
            <InputLabel id="grantType-label">Grant Type</InputLabel>
            <Select
              labelId="grantType-label"
              sx={{ width: "49%", input: { fontSize: 14 } }}
              size="small"
              id={AuthValues.grantType}
              name={AuthValues.grantType}
              value="client_credentials"
              disabled={true}
            >
              <MenuItem value="client_credentials">Client Credentials</MenuItem>
            </Select>
          </Box>
          {FORM_CONFIG_OAUTH.map((item) => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginBottom: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <InputLabel id={item.id}>{item.label}</InputLabel>
                <Tooltip title={item.hint} placement="top-end">
                  <InfoOutlined sx={{ fontSize: 15, marginLeft: "5px" }} />
                </Tooltip>
              </Box>
              <TextField
                sx={{ width: "49%", input: { fontSize: 14 } }}
                key={item.id}
                name={item.id}
                id={item.id}
                size="small"
                value={state[item.id]}
                onChange={onChange}
                required={item.isRequired}
                InputProps={{
                  endAdornment: item.warningText ? (
                    <InputAdornment position="start">
                      <Tooltip title={item.warningText} placement="top-end">
                        <WarningAmberRoundedIcon color="warning" />
                      </Tooltip>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginBottom: 2,
            }}
          >
            <InputLabel>Token</InputLabel>
            <TextField
              sx={{ width: "49%", input: { fontSize: 14 } }}
              size="small"
              value={token || "Press Get new access token"}
              disabled={true}
            />
          </Box>
          {isNeedToRefreshToken ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 2,
              }}
            >
              <Text fontSize={"14px"} color="text">
                You change auth settings. Please, press button "Get new access
                token"
              </Text>
            </Box>
          ) : null}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" type="submit">
              Get new access token
            </Button>
          </Box>
        </>
      ) : null}
      {state.auth_type === AuthConfigTypeValues.BearerToken ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: 2,
          }}
        >
          <InputLabel id={AuthValues.bearerToken}>Bearer Token</InputLabel>
          <TextField
            sx={{ width: "49%", input: { fontSize: 14 } }}
            key={AuthValues.bearerToken}
            name={AuthValues.bearerToken}
            id={AuthValues.bearerToken}
            size="small"
            value={state[AuthValues.bearerToken]}
            onChange={onChange}
          />
        </Box>
      ) : null}
    </Box>
  );
};

const checkIsNeedToRefreshToken = (
  setIsNeedToRefreshToken: (value: boolean) => void,
  access_token_url: string,
  client_secret: string,
  client_id: string,
  scope: string
) => {
  const accessTokenUrl = localStorage.getItem(AuthValues.accessTokenUrl);
  const clientSecret = localStorage.getItem(AuthValues.clientSecret);
  const clientId = localStorage.getItem(AuthValues.clientId);
  const currentScope = localStorage.getItem(AuthValues.scope);

  if (
    accessTokenUrl !== access_token_url ||
    clientSecret !== client_secret ||
    clientId !== client_id ||
    currentScope !== scope
  ) {
    setIsNeedToRefreshToken(true);
  } else {
    setIsNeedToRefreshToken(false);
  }
};
