import { useEffect } from "react";
import { useMonaco } from "@monaco-editor/react";

import { useFileSystem, useEditor } from "../../../hooks";
import { FileSystemActionType, FileEntry } from "../../../types";

export const useDiff = () => {
  const monaco = useMonaco();

  const { editorRef, switchModelToNewUri } = useEditor();

  const { diff } = useFileSystem();

  useEffect(() => {
    if (diff.length && monaco) {
      diff.forEach((diffItem) => {
        switch(diffItem.type) {
          case FileSystemActionType.AddFile: {            
            monaco.editor.createModel(
              diffItem.payload.file.content, 
              "json", 
              new monaco.Uri().with({ path: diffItem.payload.file.id })
            );
            break;
          }
          case FileSystemActionType.Rename:
          case FileSystemActionType.Move: {
            diffItem.payload.files.forEach((item) => {
              // TODO: Find out why type of payload is incorrenct.
              const file = item as FileEntry & { prevId: string };
              const oldModel = monaco.editor.getModels().find((model) => model.uri.path === file.prevId);
              const newUri =  new monaco.Uri().with({ path: file.id });
              if (oldModel) switchModelToNewUri(oldModel, newUri);
            });
            break;
          }
          case FileSystemActionType.SetActive: {
            const activeModel = monaco.editor.getModels().find(
              (model) => model.uri.path === diffItem.payload.id
            );
            if (activeModel) {
              editorRef.current.setModel(activeModel);
            }
            break;
          }
          case FileSystemActionType.Remove: {
            diffItem.payload.files.forEach((item) => {
              monaco.editor.getModels().find((model) => model.uri.path === item.id)?.dispose();
            });
            break;
          }
        }
      })
    }
  }, [monaco, editorRef, switchModelToNewUri, diff]);
};
