import { ActionMap } from "./utillity";

export type Folder = {
  path: string;
  name: string;
};

export type File = Folder & {
  content: string;
  isActive: boolean;
};

export type FileSystemStorage = {
  [id: string]: Folder | File;
} | null;

export type FileSystem = NonNullable<FileSystemStorage>;

export type FileSystemEntry = (Folder | File) & { id: string };

export type FileEntry = File & { id: string };

export type TreeNode = FileSystemEntry & {
  children?: TreeNode[];
};

export enum FileSystemActionType {
  AddFolder = "add-folder",
  AddFile = "add-file",
  Rename = "rename",
  Move = "move",
  Remove = "remove",
  RemoveAll = "remove-all",
  Change = "change",
  SetActive = "set-active",
}

export type FileSystemActionPayload = {
  [FileSystemActionType.AddFolder]: {
    parentId: string;
    name: string;
  };
  [FileSystemActionType.AddFile]: {
    parentId: string;
    name: string;
    content?: string;
  };
  [FileSystemActionType.Rename]: {
    id: string;
    name: string;
  };
  [FileSystemActionType.Move]: {
    id: string;
    parentId: string;
  };
  [FileSystemActionType.Remove]: {
    id: string;
  };
  [FileSystemActionType.RemoveAll]: {};
  [FileSystemActionType.Change]: {
    id: string;
    content: string;
  };
  [FileSystemActionType.SetActive]: {
    id: string;
  };
};

export type FileSystemDiffPayload = {
  [FileSystemActionType.AddFile]: {
    file: FileEntry;
  };
  [FileSystemActionType.Rename]: {
    files: FileEntry &
      {
        prevId: string;
      }[];
  };
  [FileSystemActionType.Move]: {
    files: FileEntry &
      {
        prevId: string;
      }[];
  };
  [FileSystemActionType.Remove]: {
    files: FileEntry[];
  };
  [FileSystemActionType.RemoveAll]: {
    files: FileEntry[];
  };
  [FileSystemActionType.SetActive]: {
    id: string;
  };
};

export type FileSystemAction = ActionMap<FileSystemActionPayload>[keyof ActionMap<FileSystemActionPayload>];

export type FileSystemDiff = ActionMap<FileSystemDiffPayload>[keyof ActionMap<FileSystemDiffPayload>];

export type FileSystemState = {
  fileSystem: FileSystem;
  diff: FileSystemDiff[];
};

export const isFile = (item: Folder | File): item is File =>
  (item as File)?.content !== undefined;

export const isFolder = (item: Folder | File): item is File => !isFile(item);
