import { UseMenuItemParams, UseMenuItemResult } from "../types";
import { 
  useAddFileItem, 
  useAddFolderItem,
  useRenameItem,
  useMoveItem,
  useRemoveItem
} from ".";

export const useMenuItems = ({
  targetItemId,
  closeMenu
}: UseMenuItemParams): UseMenuItemResult[] => {
  const addFileItem = useAddFileItem({ targetItemId, closeMenu });

  const addFolderItem = useAddFolderItem({ targetItemId, closeMenu });

  const renameItem = useRenameItem({ targetItemId, closeMenu });

  const moveItem = useMoveItem({ targetItemId, closeMenu });

  const removeItem = useRemoveItem({ targetItemId, closeMenu });

  return [
    addFileItem,
    addFolderItem,
    renameItem,
    moveItem,
    removeItem
  ];
};