import type Monaco from "monaco-editor";
import { getLanguageService, TextDocument } from "vscode-json-languageservice";

import type { ValidationResult } from "@profiler/diagnostic";
import { extractTargetNode, Severity } from "@profiler/diagnostic";
import { AlertType } from "@profiler/ui";

import { makeRequest } from "../api/request";
import { AlertData, ITextModel } from "../types";
import { ConfigUrlsTypes, TerminologyBodyType } from "../types/configUrls";
import { handleResponse } from "./handleResponse";

const vscodeJsonService = getLanguageService({});

export const FHIR_VALIDATE_COMMAND = "fhir:validate";

const TERMINOLOGY_RESOURCES_TYPES = ["CodeSystem", "ValueSet", "ConceptMap"];
const isTerminologyServer = (resourceType: string) =>
  TERMINOLOGY_RESOURCES_TYPES.includes(resourceType);
const getValidationUrl = (resourceType: string) => {
  if (isTerminologyServer(resourceType)) {
    return `${localStorage.getItem(
      ConfigUrlsTypes.TerminologyUrl
    )}/${resourceType}/$validate`;
  }
  return `${localStorage.getItem(
    ConfigUrlsTypes.FhirBaseApiUrl
  )}/${resourceType}/$validate`;
};

export const setValidationResult = (
  model: ITextModel,
  issues: ValidationResult[] | undefined = [],
  setDiagnostics: (diagnostics: Monaco.editor.IMarkerData[]) => void
) => {
  const editorText = model.getValue();

  const textDocument = TextDocument.create(
    model.uri.toString(),
    model.getLanguageId(),
    model.getVersionId(),
    editorText
  );

  const jsonDocument = vscodeJsonService.parseJSONDocument(textDocument);

  const validationResults = issues.filter(
    (validation: ValidationResult) =>
      validation.severity !== Severity.Information
  );

  const diagnostics: Monaco.editor.IMarkerData[] = [];

  validationResults.forEach((validationResult: ValidationResult) => {
    const targetNode = extractTargetNode(validationResult, jsonDocument);

    if (targetNode) {
      const { offset, length } = targetNode;
      const {
        lineNumber: startLineNumber,
        column: startColumn,
      } = model.getPositionAt(offset);
      const {
        lineNumber: endLineNumber,
        column: endColumn,
      } = model.getPositionAt(offset + length);

      diagnostics.push({
        startLineNumber,
        startColumn,
        endLineNumber,
        endColumn,
        message: validationResult.diagnostics || validationResult.details?.text,
        severity: getMonacoSeverity(validationResult.severity),
      });
    }
  });

  setDiagnostics(diagnostics);
};

const getMonacoSeverity = (fhirSeverity: Severity): Monaco.MarkerSeverity => {
  switch (fhirSeverity) {
    case Severity.Information:
      return 2;
    case Severity.Warning:
      return 4;
    case Severity.Error:
    case Severity.Fatal:
      return 8;
    default:
      // Hint.
      return 1;
  }
};

export const fhirValidate = (
  toggleLoader: () => void,
  toggleAlert: (data: AlertData) => void,
  setDiagnostics: (diagnostics: Monaco.editor.IMarkerData[]) => void
) => {
  return async (editor: Monaco.editor.ICodeEditor) => {
    toggleLoader();

    try {
      const model = editor.getModel() as ITextModel;
      const editorText = model.getValue();
      const structure = JSON.parse(editorText);
      const url = getValidationUrl(structure?.resourceType);
      let response;
      if (isTerminologyServer(structure?.resourceType)) {
        const bodyType =
          localStorage.getItem(
            TerminologyBodyType.TerminologyValidationBodyType
          ) || "";
        response = await makeRequest({ url, body: structure, bodyType });
      } else {
        response = await makeRequest({ url, body: structure });
      }

      if (handleResponse(response, toggleAlert)) {
        setValidationResult(model, response.body?.issue, setDiagnostics);
      }
    } catch (error) {
      toggleAlert({
        message: (error as { message: string }).message,
        type: AlertType.Error,
      });
    } finally {
      toggleLoader();
    }
  };
};
