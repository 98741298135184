import { ChangeEvent, useCallback, useMemo, useState } from "react";
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogContentText,
  DialogTitle, 
  DialogActions,
  TextField,
  Button,
  MenuItem
} from "@mui/material";

import { capitalizeFirstLetter } from "@profiler/profile-view";

import { useDialog, useEventListener } from "../hooks";

export const Dialog = () => {
  const { data, setData } = useDialog();

  const {
    title,
    description,
    fields,
    resolve,
    okText = "Save",
    cancelText = "Cancel"
  } = data ?? {};

  const [result, setResult] = useState<Record<string, string>>({});

  const [errors, setErrors] = useState<Record<string, string | undefined>>({});

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {    
    if (fields) {      
      const { value, name } = event.target;
      
      if (name) {
        const { validate } = fields.find((field) => field.name === name) || {};
        const error = validate?.(value);
        setErrors((errors) => ({
          ...errors,
          [name]: error
        }));
        setResult((values) => ({
          ...values,
          [name]: value
        }));
      }
    }
  }, [fields, setResult, setErrors]);

  const onClose = useCallback(() => {
    setData(null);
    resolve?.(false);
  }, [resolve, setData]);

  const onSubmit = useCallback(() => {
    setData(null);
    resolve?.(Object.keys(result).length ? result : true);
  }, [resolve, setData, result]);

  useEventListener("keypress", (event) => {
    if (event.key === "Enter") onSubmit();
  });

  const isSubmitDisabled = useMemo(() => 
    fields?.some(
      (field) => (field.required && !result[field.name]) || !!errors[field.name]
    ), 
    [fields, result, errors]
  );

  return data && (
    <MuiDialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {description && (
          <DialogContentText>
            {description}
          </DialogContentText>
        )}
        {fields?.map((field) => {
          const {
            name, 
            defaultValue, 
            options 
          } = field;
          return (
            <TextField
              fullWidth
              autoFocus={!options}
              select={!!options}
              error={!!errors[name]}
              helperText={errors[name]}
              defaultValue={defaultValue}
              margin="dense"
              key={name}
              id={name}
              name={name}
              label={capitalizeFirstLetter(name)}
              variant="standard"
              onChange={onChange}
            >
              {options?.map((option) => (
                <MenuItem 
                  disabled={option.disabled} 
                  key={option.value} 
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelText}</Button>
        <Button 
          onClick={onSubmit} 
          disabled={isSubmitDisabled}
        >
          {okText}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};