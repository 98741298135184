import {
  useCallback,
  SyntheticEvent,
  useMemo,
  useContext,
  useEffect,
} from "react";
import { useMonaco } from "@monaco-editor/react";

import { FhirResource, searchFragmentByDataset } from "@profiler/profile-view";
import { AlertType, Box } from "@profiler/ui";

import {
  useAlert,
  useEditor,
  useFileSystem,
  useHints,
  useLoader,
} from "../hooks";
import { findPositions, getFileSystemItemId } from "../utils";
import { AppContext } from "../context/AppContext";
import { ExpandContentType } from "../types";
import { ConfigUrlsTypes } from "../types/configUrls";
import { makeRequest } from "../api/request";

export const ProfileView = () => {
  const { activeFile } = useFileSystem();

  const { toggle: toggleAlert } = useAlert();
  const { toggle: toggleLoader } = useLoader();

  const { expandContent, setExpandContent } = useContext(AppContext);

  const monaco = useMonaco();
  const { editorRef, getModelMarkers } = useEditor();

  const handleRevealElement = useCallback(
    (event: SyntheticEvent<HTMLAnchorElement>) => {
      const editor = editorRef?.current;
      if (!monaco || !editor) return;

      const model = editor.getModel();
      if (!model) return;

      const searchTerm = searchFragmentByDataset(event.currentTarget.dataset);
      if (!searchTerm) return;

      const [position] = findPositions(model, searchTerm);
      if (position) {
        const { lineNumber, column } = position;
        editor.revealLineInCenter(lineNumber, 0);
        editor.setSelection(
          new monaco.Range(
            lineNumber,
            column,
            lineNumber,
            model?.getLineLastNonWhitespaceColumn(lineNumber) || column
          )
        );
        editor.focus();
      }
    },
    [monaco, editorRef]
  );

  const hasFhirErrors = useMemo(
    () =>
      !!activeFile && !!getModelMarkers(getFileSystemItemId(activeFile)).length,
    [activeFile, getModelMarkers]
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let structure;
    try {
      structure = JSON.parse(activeFile?.content);
    } catch (error) {
      structure = null;
    }

    const { id, resourceType } = structure || {};

    if (
      resourceType === "ValueSet" &&
      !expandContent[id as keyof ExpandContentType]
    ) {
      toggleLoader();
      const fetchData = async () => {
        try {
          const url = `${localStorage.getItem(
            ConfigUrlsTypes.TerminologyUrl
          )}/ValueSet/${id}/$expand`;
          const response = await makeRequest({ url, method: "GET" });
          setExpandContent((prevState: ExpandContentType) => ({
            ...prevState,
            [response?.body?.id]: response?.body,
          }));
        } catch (error) {
          toggleAlert({
            message: (error as { message: string }).message,
            type: AlertType.Error,
          });
        } finally {
          toggleLoader();
        }
      };

      fetchData();
    }
  }, [
    activeFile,
    // expandContent,
    setExpandContent,
    toggleAlert,
    toggleLoader,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const { hintId } = useHints();

  if (!activeFile) return null;

  return (
    <Box overflow="auto" width="100%" padding={1}>
      <FhirResource
        input={activeFile.content}
        hasFhirErrors={hasFhirErrors}
        onRevealElement={handleRevealElement}
        expandContent={expandContent}
        hintId={hintId}
      />
    </Box>
  );
};
