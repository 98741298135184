import Editor from "@monaco-editor/react";

import { Resizable, ResizeHandle } from "@profiler/ui";

import { HintsId, useHints, useResize, useTheme } from "../../hooks";
import {
  useInitialization,
  useDiff,
  useCommands,
  useModelChange,
} from "./hooks";

export const MonacoEditor = () => {
  const { ref, onResize } = useResize();
  const { onMount } = useCommands();
  const { onChange } = useModelChange();
  const { monacoTheme } = useTheme();
  const { hintId, targetStyle } = useHints();

  useInitialization();
  useDiff();

  return (
    <Resizable
      position="relative"
      minWidth="50%"
      ref={ref}
      style={hintId === HintsId.Editor ? targetStyle : undefined}
    >
      <Editor theme={monacoTheme} onChange={onChange} onMount={onMount} />
      <ResizeHandle onMouseDown={onResize} />
    </Resizable>
  );
};
