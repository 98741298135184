export const generateParameters = (resource: any) => {
  const name = resource?.resourceType?.charAt(0).toLowerCase() + resource?.resourceType?.slice(1);
  const parameter = [{
    name,
    resource
  }];

  return ({
    resourceType: "Parameters",
    parameter
  });
}
