import { useState } from "react";
import { Box, Button } from "@mui/material";

import { defaultSettings } from "./constants";
import { ApiUrls } from "./ApiUrls";
import { TerminologyConfig } from "./TerminologyConfig";
import { Snippet } from "./Snippet";
import { Auth } from "./Auth";

export type DefaultStateType = {
  [key: string]: string;
};

export const Settings = () => {
  const [state, setState] = useState<DefaultStateType>(defaultSettings());
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleSubmit = () => {
    for (const key in state) {
      localStorage.setItem(key, state[key]);
    }
    setIsSubmitted(true);
  };

  const onChange = (e: { target: { value: string; name: string } }) => {
    setIsSubmitted(false);
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div style={{ width: 600, padding: "24px 16px" }}>
      <ApiUrls state={state} onChange={onChange} />
      <TerminologyConfig state={state} onChange={onChange} />
      <Snippet state={state} onChange={onChange} />
      <Auth state={state} onChange={onChange} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginRight: 1,
        }}
      >
        <Button onClick={handleSubmit} variant="outlined">
          {isSubmitted ? "Saved" : "Save"}
        </Button>
      </Box>
    </div>
  );
};
