import React, { useCallback, useMemo } from "react";
import {
  AutoFixHigh,
  ContentCopy,
  ContentPaste,
  FileDownload,
  FileOpen,
  FileUpload,
  InfoOutlined,
} from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import jszip from "jszip";

import {
  Flexbox,
  Image,
  Loader,
  SvgButton,
  Text,
  ThemeSwitch,
} from "@profiler/ui";
import logo from "@profiler/ui/image/logo.png";

import {
  HintsId,
  useEditor,
  useFileSystem,
  useHints,
  useLoader,
  useTheme,
} from "../hooks";
import { useOpenFiles } from "./Sidebar/hooks";
import { HEADER_HEIGHT } from "../constants";
import { selectTree } from "../context/file-system";
import { isFile, TreeNode } from "../types";

const zipItem = (zip: jszip, item: TreeNode) => {
  if (isFile(item)) {
    zip.file(item.name, item.content);
  } else {
    const folder = zip.folder(item.name) as jszip;
    item.children?.forEach((child) => zipItem(folder, child));
  }
};

export const Header = () => {
  const { editorRef, replaceEditorContent } = useEditor();
  const { mode, setMode: setThemeMode } = useTheme();
  const { isLoading } = useLoader();
  const { fileSystem, activeFileId } = useFileSystem();

  const tree = useMemo(() => selectTree(fileSystem), [fileSystem]);

  const { openFileSelector } = useOpenFiles();
  const { hintId, goToNextHint, targetStyle } = useHints();

  const TOOLBAR_HINT_STYLE = {
    ...targetStyle,
    padding: 10,
    marginLeft: "-10px",
    marginRight: "-10px",
  };
  const THEME_SWITCHER_HINT_STYLE = {
    ...targetStyle,
    padding: 8,
    marginRight: "-8px",
    marginLeft: "-8px",
  };

  const onCopy = useCallback(async () => {
    if ("clipboard" in navigator) {
      const editorText = editorRef.current.getModel()?.getValue();

      await navigator.clipboard.writeText(editorText || "");
    }
  }, [editorRef]);

  const onPaste = useCallback(async () => {
    if ("clipboard" in navigator) {
      const editorText = await navigator.clipboard.readText();

      replaceEditorContent(editorText);
    }
  }, [replaceEditorContent]);

  const onFormat = useCallback(() => {
    const editorText = editorRef.current.getModel()?.getValue();

    if (editorText) {
      replaceEditorContent(JSON.stringify(JSON.parse(editorText), null, 2));
    }
  }, [editorRef, replaceEditorContent]);

  const onDownload = useCallback(() => {
    const zip = new jszip();

    tree.forEach((root) => zipItem(zip, root));

    zip.generateAsync({ type: "blob" }).then((content) => {
      const downloadUrl = URL.createObjectURL(content);
      const a = document.createElement("a");
      a.href = downloadUrl;
      document.body.appendChild(a);
      a.click();
    });
  }, [tree]);

  return (
    <Flexbox
      height={HEADER_HEIGHT}
      flexFlow="row nowrap"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="contrast"
      py={2}
      px={3}
    >
      <Flexbox
        flexFlow="row nowrap"
        justifyContent="center"
        alignItems="center"
        style={hintId === HintsId.Toolbar ? TOOLBAR_HINT_STYLE : undefined}
      >
        <Tooltip title="Copy">
          <SvgButton padding={0} backgroundColor="transparent" onClick={onCopy}>
            <ContentCopy />
          </SvgButton>
        </Tooltip>
        <Tooltip title="Paste">
          <SvgButton
            padding={0}
            ml={2}
            backgroundColor="transparent"
            onClick={onPaste}
          >
            <ContentPaste />
          </SvgButton>
        </Tooltip>
        <Tooltip title="Format JSON">
          <SvgButton
            padding={0}
            ml={2}
            backgroundColor="transparent"
            onClick={onFormat}
          >
            <AutoFixHigh />
          </SvgButton>
        </Tooltip>
        <Tooltip title="Upload .json">
          <SvgButton
            padding={0}
            ml={2}
            backgroundColor="transparent"
            onClick={openFileSelector}
          >
            <FileUpload />
          </SvgButton>
        </Tooltip>
        <Tooltip title="Download .zip">
          <SvgButton
            padding={0}
            ml={2}
            backgroundColor="transparent"
            onClick={onDownload}
          >
            <FileDownload />
          </SvgButton>
        </Tooltip>
        <Flexbox
          position="absolute"
          left="300px"
          flexFlow="row nowrap"
          justifyContent="center"
          alignItems="center"
          gap={4}
          color="text"
        >
          <FileOpen fontSize="small" />
          <Typography variant="caption">
            {activeFileId ?? "No file selected"}
          </Typography>
        </Flexbox>
      </Flexbox>

      <Flexbox
        flexFlow="row nowrap"
        justifyContent="center"
        alignItems="center"
      >
        <Image src={logo} alt="Logo" width="30px" />
        <Text fontSize={4} color="text" ml={2}>
          Kodjin FHIR Profiler
        </Text>
      </Flexbox>

      <Flexbox
        flexFlow="row nowrap"
        justifyContent="center"
        alignItems="center"
      >
        <SvgButton
          padding={0}
          mr={4}
          backgroundColor="transparent"
          onClick={goToNextHint}
        >
          <InfoOutlined />
        </SvgButton>
        <div
          style={
            hintId === HintsId.Themes ? THEME_SWITCHER_HINT_STYLE : undefined
          }
        >
          <ThemeSwitch mode={mode} onChange={setThemeMode} />
        </div>
        <Loader visibility={isLoading ? "visible" : "hidden"} />
      </Flexbox>
    </Flexbox>
  );
};
