import uniq from "lodash/uniq";
import unionBy from "lodash/unionBy";

import {
  FileSystem, 
  FileSystemEntry,
  File,
  Folder,
  TreeNode,
  isFile
} from "../../types";
import { ROOT_FOLDER } from "../../constants";

const toTreeNode = (
  items: FileSystemEntry[], 
  parentId = ROOT_FOLDER
): TreeNode[] => 
  items
    .filter((item) => item.path === parentId)
    .map((item) => ({ ...item, children: toTreeNode(items, item.id) }));

export const selectTree = (fileSystem: FileSystem, filter?: string) => {
  const fileSystemItems = Object.entries(fileSystem).map(([id, item]) => ({
    ...item,
    id
  }));

  if (filter) {
    const filteredItems = fileSystemItems.filter((item) =>
      (item.path + item.name).toLowerCase().includes(filter.toLowerCase())
    );
    const uniqPath = uniq(filteredItems.map((item) => item.path)).join("");
    const filteredFolders = fileSystemItems.filter((item) => uniqPath.includes(`/${item.name}/`));

    return toTreeNode(unionBy(filteredItems, filteredFolders, "id"));
  }

  return toTreeNode(fileSystemItems);
};

export const selectProfile = (file: File) => {
  try {
    return JSON.parse(file.content);
  } catch {
    return undefined;
  }
};

export const selectActiveId = (fileSystem: FileSystem) => {
  const [id] = Object.entries(fileSystem).find(
    ([,item]) => isFile(item) && item.isActive
  ) ?? [];

  return id as string;
};

export const selectFoldersIds = (fileSystem: FileSystem) => {
  return Object.entries(fileSystem).filter(([,item]) => !isFile(item)).map(([id]) => id);
};

export const selectIds = (fileSystem: FileSystem) => {
  return Object.keys(fileSystem);
};

export const selectChildren = (fileSystem: FileSystem, folderId: string): (File | Folder)[] => {
  return Object.values(fileSystem).filter((item) => item.path === folderId);
};