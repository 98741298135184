import { useCallback, useState } from "react";

export const useLoader = () => {
  const  [isLoading, setIsLoading] = useState(false);

  const toggle = useCallback(() => {
    setIsLoading((prevIsLoading) => !prevIsLoading);
  }, [setIsLoading]);

  return { isLoading, toggle };
};
