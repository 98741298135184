import { useContext, useCallback } from "react";
import { useMonaco } from "@monaco-editor/react";
import Monaco from "monaco-editor";

import { AppContext } from "../context/AppContext";

export const useEditor = () => {
  const { editorRef } = useContext(AppContext) || {};

  const monaco = useMonaco();

  const replaceEditorContent = useCallback((text: string) => {
    const model = editorRef.current.getModel();
    if (model) {
      editorRef.current.executeEdits(null, [{
        range: model.getFullModelRange(),
        text: text
      }], monaco ? [new monaco.Selection(1, 1, 1, 1)] : undefined)
    }
  }, [monaco, editorRef]);

  const switchModelToNewUri = useCallback((
    oldModel: Monaco.editor.ITextModel, 
    newUri: Monaco.Uri
  ) => {
    if (monaco) {
      const newModel = monaco.editor.createModel(
        oldModel.getValue(),
        oldModel.getLanguageId(),
        newUri
      );
    
      const fsPath = newUri.fsPath; // \\filename
      const formatted = newUri.toString(); // file:///filename
    
      // @ts-ignore
      const editStacks = oldModel._commandManager._undoRedoService._editStacks;
    
      const newEditStacks = new Map();
    
      const adjustEditStack = (c: any) => {
        c.actual.model = newModel;
        c.resourceLabel = fsPath;
        c.resourceLabels = [fsPath];
        c.strResource = formatted;
        c.strResources = [formatted];
      };
    
      editStacks.forEach((s: any) => {
        s.resourceLabel = fsPath;
        s.strResource = formatted;
    
        s._future.forEach(adjustEditStack);
        s._past.forEach(adjustEditStack);
    
        newEditStacks.set(formatted, s);
      })
    
      // @ts-ignore
      newModel._commandManager._undoRedoService._editStacks = newEditStacks;
    
      oldModel.dispose();
    
      return newModel;
    }
  }, [monaco]);

  const getModelMarkers = useCallback((path: string): Monaco.editor.IMarker[] => {
    if (monaco) {
      const modelUri = new monaco.Uri().with({ path });
      return monaco.editor.getModelMarkers({ resource: modelUri });
    }
    return [];
  }, [monaco]);

  return { 
    editorRef, 
    replaceEditorContent, 
    switchModelToNewUri,
    getModelMarkers
  };
};
