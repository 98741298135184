import type Monaco from "monaco-editor";

export const findPositions = (
  model: Monaco.editor.ITextModel,
  searchTerm: string
): Monaco.Position[] => {
  const text = model.getValue();

  const matches = Array.from(text.matchAll(new RegExp(`${searchTerm}`, "g")));

  const result = matches
  .map((match) => match.index !== undefined ? model.getPositionAt(match.index) : undefined)
  .filter((position): position is Monaco.Position => position !== undefined);
 
  return result;
};
