import React, { useCallback, useMemo } from "react";
import { DriveFileMove } from "@mui/icons-material";
import sortBy from "lodash/sortBy";

import { useFileSystem, useDialog } from "../../../hooks";
import { useTargetItem } from "./useTargetItem";
import { FileSystemActionType } from "../../../types";
import { UseMenuItemParams, UseMenuItemResult } from "../types";
import { validatePathChange } from "../validate";
import { ROOT_FOLDER } from "../../../constants";
import { selectFoldersIds } from "../../../context/file-system";

export const useMoveItem = ({
  targetItemId,
  closeMenu
}: UseMenuItemParams): UseMenuItemResult => {
  const { dispatch, fileSystem } = useFileSystem();

  const { isRoot, isFolder, targetItem } = useTargetItem({ targetItemId });

  const { getSubmitted } = useDialog();

  const moveOptions = useMemo(() => {
    if (targetItemId && targetItem) {
      const options = [
        { 
          label: ROOT_FOLDER, 
          value: ROOT_FOLDER, 
          disabled: targetItem.path === ROOT_FOLDER 
        },
        ...selectFoldersIds(fileSystem).map((item) => {              
          return {
            label: item,
            value: item,
            disabled: targetItem.path === item
              || (isFolder && item.startsWith(targetItemId))
          };
        })
      ];

      if (options.some((option) => !option.disabled)) {
        return sortBy(options, (option) => option.value.length);
      }
    }
    return null;
  }, [targetItemId, targetItem, isFolder, fileSystem]);

  const onMove = useCallback(async () => {
    closeMenu();
    
    if (!targetItemId || !targetItem || !moveOptions) return;

    const result = await getSubmitted({
      title: `Move ${targetItem.name} to`,
      fields: [{
        name: "path",
        required: true,
        validate: validatePathChange({ targetItem, fileSystem }),
        options: moveOptions
      }]
    });

    if (typeof result === "object" && result.path) {
      dispatch({
        type: FileSystemActionType.Move,
        payload: {
          id: targetItemId,
          parentId: result.path
        }
      });
    }
  }, [
    targetItemId,
    targetItem,
    fileSystem,
    moveOptions,
    closeMenu, 
    getSubmitted,
    dispatch
  ]);

  return {
    available: !isRoot && !!moveOptions,
    name: "Move to",
    icon: <DriveFileMove />,
    onClick: onMove,
    divider: true
  };
};