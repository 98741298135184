import React, { useCallback } from "react";
import { CreateNewFolderOutlined } from "@mui/icons-material";

import { useFileSystem, useDialog } from "../../../hooks";
import { useTargetItem } from "./useTargetItem";
import { FileSystemActionType } from "../../../types";
import { UseMenuItemParams, UseMenuItemResult } from "../types";
import { validateName } from "../validate";

export const useAddFolderItem = ({
  targetItemId,
  closeMenu
}: UseMenuItemParams): UseMenuItemResult => {
  const { dispatch, fileSystem } = useFileSystem();

  const { isFolder } = useTargetItem({ targetItemId });

  const { getSubmitted } = useDialog();

  const onAddFolder = useCallback(async () => {
    closeMenu();

    if (!targetItemId) return;

    const result = await getSubmitted({
      title: `Add folder to ${targetItemId}`,
      fields: [{
        name: "name",
        required: true,
        validate: validateName({ targetItemId, fileSystem })
      }]
    });
    
    if (typeof result === "object" && result.name) {
      dispatch({
        type: FileSystemActionType.AddFolder,
        payload: {
          parentId: targetItemId,
          name: result.name
        }
      });
    }
  }, [
    targetItemId,
    fileSystem,
    closeMenu,
    getSubmitted,
    dispatch
  ]);

  return {
    available: isFolder,
    name: "Add folder",
    icon: <CreateNewFolderOutlined />,
    onClick: onAddFolder,
    divider: true
  };
};