export enum ConfigUrlsTypes {
  TerminologyUrl = "TERMINOLOGY_URL",
  FhirBaseApiUrl = "FHIR_BASE_API_URL",
}

export enum TerminologyBodyType {
  TerminologyValidationBodyType = "TERMINOLOGY_VALIDATION_BODY_TYPE",
}

export enum TerminologyBodyTypeValues {
  FhirResource = "fhir_resource",
  FormData = "form_data",
}

export enum ConfigSnippetValues {
  publisher = "snippet_publisher",
  version = "snippet_version",
  date = "snippet_date",
  fhirVersion = "snippet_fhirVersion",
  status = "snippet_status",
}

export enum AuthValues {
  authType = "auth_type",
  bearerToken = "bearer_token",
  accessTokenUrl = "access_token_url",
  clientId = "client_id",
  clientSecret = "client_secret",
  scope = "scope",
  grantType = "grant_type",
  accessToken = "access_token",
}

export enum AuthConfigTypeValues {
  NoAuth = "noAuth",
  BearerToken = "bearerToken",
  OAuth2 = "OAuth2",
}
