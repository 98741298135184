import Monaco from "monaco-editor";

import { getIssueBySeverity, messageFromIssues } from "@profiler/diagnostic";
import { AlertType } from "@profiler/ui";

import { ConfigUrlsTypes } from "../types/configUrls";
import { makeRequest } from "../api/request";
import { AlertData, ExpandContentType, ITextModel } from "../types";
import { generateParameters } from "../utils/generateParameters";

export const FHIR_EXPAND_COMMAND = "fhir:expand";

const getExpandUrl = () => {
  return `${localStorage.getItem(
    ConfigUrlsTypes.TerminologyUrl
  )}/ValueSet/$expand`;
};

export const fhirExpand = (
  toggleLoader: () => void,
  toggleAlert: (data: AlertData) => void,
  setExpandContent: (value: ExpandContentType) => void
) => {
  return async (editor: Monaco.editor.ICodeEditor): Promise<any> => {
    toggleLoader();

    try {
      const model = editor.getModel() as ITextModel;
      const editorText = model.getValue();
      const structure = JSON.parse(editorText);

      if (structure.resourceType !== "ValueSet") {
        throw new Error("Expand value set is available only for ValueSet");
      }

      const url = getExpandUrl();
      const parameters = generateParameters(structure);
      const response = await makeRequest({ url, body: parameters });

      if (response.status !== 200) {
        const issueBySeverity = getIssueBySeverity(response.body?.issue);
        throw new Error(messageFromIssues(issueBySeverity));
      }

      setExpandContent((prevState: ExpandContentType) => ({
        ...prevState,
        [response?.body?.id]: response?.body,
      }));
    } catch (error) {
      toggleAlert({
        message: (error as { message: string }).message,
        type: AlertType.Error,
      });
    } finally {
      toggleLoader();
    }
  };
};
