import { FileSystem, File, Folder } from "../../../types";
import { getFileSystemItemId } from "../../../utils";
import { selectIds, selectChildren } from "../../../context/file-system";

type ValidateNameParams = {
  targetItemId: string;
  fileSystem: FileSystem;
};

type ValidateChangeParams = {
  targetItem: File | Folder;
  fileSystem: FileSystem;
};

export const validateName = ({
  targetItemId,
  fileSystem
}: ValidateNameParams) => (name: string) => {
  const uniqueIds = selectIds(fileSystem);
  const id = getFileSystemItemId({ name, path: targetItemId as string })
  if (uniqueIds.includes(id)) return "Name should be unique";
};

export const validateNameChange = ({
  targetItem,
  fileSystem
}: ValidateChangeParams) => (name: string) => {
  const uniqueIds = selectIds(fileSystem);
  const id = getFileSystemItemId({ name, path: targetItem.path })
  if (uniqueIds.includes(id)) return "Name should be unique";
};

export const validatePathChange = ({
  targetItem,
  fileSystem
}: ValidateChangeParams) => (path: string) => {
  if (targetItem) {
    const pathItems = selectChildren(fileSystem, path);
    if (pathItems.some((item) => item.name === targetItem.name)) {
      return "Target folder contains item with the same name. Rename either it or the current one.";
    }
  }
};